import { CalendarEvent } from '@/components/calendar/small-calendar'
import { Calendar as CalendarIcon, X } from '@phosphor-icons/react'
import { format, parseISO } from 'date-fns'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

export default function DayPreview({
  events,
  selectedDay,
  onClose,
}: {
  events: CalendarEvent[]
  selectedDay: string
  onClose: () => void
}) {
  const { t } = useTranslation()
  return (
    <div>
      <div className="fixed top-0 left-0 z-30 w-full h-screen bg-ui1 opacity-75"></div>
      <div className="flex justify-center fixed top-0 left-0 z-40 w-full h-screen align-center items-center overflow-scroll">
        <div className="flex flex-col absolute items-start p-8 z-50 bg-white rounded-md shadow-md w-[500px] h-fit overflow-auto">
          <div className="relative w-full">
            <div className="text-lg font-bold flex flex-row justify-between pb-4">
              {events.length} {t('campaigns_on')}{' '}
              {format(parseISO(selectedDay), 'dd.MM.yyyy')}
              <button onClick={onClose}>
                <X />
              </button>
            </div>

            <div className="overflow-y-scroll h-96 relative">
              {events.map((event, index) => (
                <div className="py-4" key={`current-event-${index}`}>
                  <div className="flex my-1">
                    <div
                      className={cx('rounded-2xl h-12 w-1.5 text-black-1', {
                        'bg-marine-300': event.type === 'campaign',
                        'bg-green-50': event.type === 'holiday',
                      })}
                    ></div>
                    <div className="ml-2">
                      <p className="text-black-3 text-lg font-bold overflow-hidden">
                        {event.data.name}
                      </p>
                      <div className="flex text-sm items-center gap-1">
                        <CalendarIcon size={16} weight="fill" />
                        {`${format(
                          parseISO(event.data.startDate),
                          'dd/MM/yyyy',
                        )} - ${format(
                          parseISO(event.data.endDate),
                          'dd/MM/yyyy',
                        )}`}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
