import { gql } from '@apollo/client'

export default gql`
  query OneCommunityFormEntities($campaignId: String!, $formIds: [String]!) {
    oneCommunityFormEntities(campaignId: $campaignId, formIds: $formIds) {
      id
      firstName
      middle
      lastName
      email
      address
      birthday
      campaignId
      campaignIncentiveId
      phoneNumber
      sku
      digitalCode
      uniqueCode
    }
  }
`
