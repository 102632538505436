import { useMemo, useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/ui/button'
import { MultiSelect } from '@/components/multi-select'
import {
  EndUsersUploadedError,
  Form,
} from '../../../../../../server/src/graphql'
import FORMS from '../../../../graphql/campaign/queries/integrations/one-community-forms'
import Loading from '../../../../components/loading'
import FORM_ENTITIES from '../../../../graphql/campaign/queries/integrations/one-community-form-entities'
import { GetCampaignFragment } from '../../../../graphql/campaign/fragments/get-campaign'

export default function OneCommunityForm({
  campaign,
  onComplete,
  setErrors,
  setShowModal,
}: {
  campaign: GetCampaignFragment
  onComplete: () => void
  setErrors: (errors?: EndUsersUploadedError[]) => void
  setShowModal: (show: boolean) => void
}) {
  const isOneCommunityFormsCampaign = useMemo(
    () =>
      campaign?.campaignCategory === 'lottery' &&
      campaign?.customer?.integrations?.some(
        (i) => i?.type === 'ONE_COMMUNITY' && i.features?.forms?.enabled,
      ),
    [campaign?.campaignCategory, campaign?.customer?.integrations],
  )
  const { t } = useTranslation()
  const { data: oneCommunityForms, loading: areFormsLoading } = useQuery(
    FORMS,
    {
      variables: { campaignId: campaign.id },
    },
  )
  const [selectedForms, setSelectedForms] = useState<string[]>(
    campaign.optionalSettings?.oneCommunity?.formIds ?? [],
  )
  const [fetchFormEntities, { loading }] = useLazyQuery(FORM_ENTITIES, {
    variables: {},
    onCompleted: () => onComplete(),
    onError: (error) => {
      const originalError = error.graphQLErrors?.at(0)?.extensions as {
        originalError: { message: EndUsersUploadedError[] }
      }
      setErrors(originalError?.['originalError']?.['message'])
      setShowModal(true)
    },
  })

  if (!isOneCommunityFormsCampaign) {
    return <></>
  }
  if (areFormsLoading || loading) return <Loading />
  return (
    <div className="flex flex-row justify-between bg-ui1 rounded-lg py-4 px-2 items-center">
      <div className="text-gray-2 text-sm">
        <>
          <h1 className="px-1 text-base font-bold">
            {t('api_integration.lottery_header')}
          </h1>
          <p className="p-1 text-sm font-normal">
            {t('api_integration.lottery_text')}
          </p>
        </>

        <div className="w-1/5 grid">
          <MultiSelect
            options={oneCommunityForms?.oneCommunityForms?.map(
              (form: Form) => ({
                value: form.id?.toString(),
                label: form.label,
                data: { id: form.id, label: form.label },
              }),
            )}
            selected={selectedForms}
            onChange={setSelectedForms}
            className="bg-white w-80"
          />
        </div>
      </div>
      <Button
        onClick={() => {
          fetchFormEntities({
            variables: {
              campaignId: campaign.id,
              formIds: selectedForms,
            },
          })
        }}
        className="flex flex-row cursor-pointer text-white"
        disabled={loading || !selectedForms?.length}
      >
        {loading ? 'Loading...' : t('api_integration.sync')}
      </Button>
    </div>
  )
}
