import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './en'
import nl from './nl'

export const defaultNS = 'translation'
export const resources = {
  en: {
    translation: en,
  },
  nl: {
    translation: nl,
  },
} as const

i18n.use(initReactI18next).init({
  resources,
  lng: 'nl',
  ns: [defaultNS],
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
})
export default i18n
