import { Calendar, ToolbarProps, dateFnsLocalizer } from 'react-big-calendar'
import {
  eachDayOfInterval,
  format,
  getDay,
  isToday,
  parse,
  startOfWeek,
} from 'date-fns'
import nl from 'date-fns/locale/nl'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import HOLIDAYS_QUERY from '../../graphql/campaign/queries/holidays'
import cx from 'classnames'
import { Campaign, Holiday } from '../../../../server/src/graphql'

import {
  ArrowLeft as ChevronLeftIcon,
  ArrowRight as ChevronRightIcon,
} from '@phosphor-icons/react'
import { enGB } from 'date-fns/locale'
import { createPortal } from 'react-dom'
import DayPreview from '@/pages/campaign/campaign-details/day-preview'

const locales = {
  'en-US': enGB,
  'nl-NL': nl,
}

export type Agenda = {
  name: string
  startDate: string
  endDate: string
  type: string
  data: Holiday | Campaign
  details: string
  continuesAfter: boolean
  continuesPrior: boolean
}

export type CalendarEvent = {
  title: JSX.Element
  start: string
  end: string
  type: string
  data: Agenda
}

type HolidayResponse = {
  campaigns: Agenda[]
  holidays: Agenda[]
}

type CalendarProps = {
  className?: string
}

export const AlphaSmallCalendar = ({ className = 'w-full' }: CalendarProps) => {
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  })
  const [showModal, setShowModal] = useState(false)
  const [selectedDay, setSelectedDay] = useState<string>()
  const { t } = useTranslation()
  const { data, loading } = useQuery<HolidayResponse>(HOLIDAYS_QUERY)

  useEffect(() => {
    if (selectedDay) {
      setShowModal(true)
    }
  }, [selectedDay])

  const toEvent = (styleType: string) => (event: Agenda) => {
    return (
      {
        title: (
          <div
            className={cx(
              'flex bg-opacity-75 items-center py-0.5 rounded-full h-5 px-1 mx-0',
              {
                'bg-marine-300': styleType === 'campaign',
                'bg-green-50': styleType === 'holiday',
              },
            )}
          >
            <p
              className="text-ellipsis overflow-hidden truncate text-black-1 px-2 text-sm"
              id="title"
            >
              {event.name}
            </p>
          </div>
        ),
        start: event.startDate,
        end: event.endDate,
        type: styleType,
        data: event,
      } || {}
    )
  }

  const events = useMemo(() => {
    const allDates = [
      ...(data?.holidays?.map(toEvent('holiday')) || []),
      ...(data?.campaigns?.map(toEvent('campaign')) || []),
    ]
      .reduce((agg: Date[], event: CalendarEvent) => {
        return [
          ...agg,
          ...eachDayOfInterval(
            {
              start: new Date(event.start),
              end: new Date(event.end),
            } || {},
          ),
        ]
      }, [])
      .map((d) => format(d, 'yyyy-MM-dd'))

    return (
      Array.from(new Set(allDates))
        .filter((d) => d !== undefined)
        .map(
          (date: string) =>
            ({
              title: (
                <div
                  className={cx(
                    'bg-primary flex bg-opacity-75 items-center py-0.5 rounded-full h-5 px-1 mx-0',
                  )}
                >
                  <p
                    className="text-ellipsis overflow-hidden truncate text-black-1 px-2 text-sm"
                    id="title"
                  >
                    0
                  </p>
                </div>
              ),
              start: date,
              end: date,
              type: 'campaign',
              data:
                ({
                  name: date,
                  startDate: date,
                  endDate: date,
                  type: 'Campaign',
                  data: {} as Campaign,
                  details: '',
                  continuesAfter: false,
                  continuesPrior: false,
                } as Agenda) ?? ({} as Agenda),
            } as CalendarEvent),
        ) || []
    )
  }, [data])
  if (loading) return <div>{t('loading')}</div>

  const Portal = () => {
    const allDates = [
      ...(data?.holidays?.map(toEvent('holiday')) || []),
      ...(data?.campaigns?.map(toEvent('campaign')) || []),
    ]
    const currentEvents = allDates?.filter((e) => {
      if (
        selectedDay &&
        new Date(e.start).getTime() <= new Date(selectedDay).getTime() &&
        new Date(e.end).getTime() >= new Date(selectedDay).getTime()
      ) {
        return e
      }
    })
    return (
      <>
        {showModal &&
          selectedDay &&
          createPortal(
            <DayPreview
              events={currentEvents}
              selectedDay={selectedDay}
              onClose={() => setShowModal(false)}
            />,
            document.body,
          )}
      </>
    )
  }

  const eventStyleGetter = () => ({
    style: { backgroundColor: 'white' },
  })

  const toolbar = (
    toolbar: ToolbarProps<{
      title: Element
      start: string
      end: string
      type: string
      data: Agenda
    }>,
  ) => {
    return (
      <header className="bg-grey-100 flex items-center justify-between border-b border-gray-200 px-6 py-4 lg:flex-none">
        <div className="flex items-center">
          <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
            <button
              type="button"
              className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon
                className="h-5 w-5"
                aria-hidden="true"
                onClick={() => toolbar.onNavigate('PREV')}
              />
            </button>
            <button
              type="button"
              className="hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block w-40"
            >
              {toolbar.label}
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              type="button"
              className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon
                className="h-5 w-5"
                aria-hidden="true"
                onClick={() => toolbar.onNavigate('NEXT')}
              />
            </button>
          </div>
        </div>
      </header>
    )
  }

  return (
    <div className={cx('lg:flex lg:flex-col', className)}>
      <Portal />
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        components={{
          toolbar,
          timeGutterWrapper: () => <div>1</div>,
          month: {
            header: (header) => {
              return (
                <div className="py-2">
                  {header.label.charAt(0)}
                  <span className="sr-only sm:not-sr-only">
                    {header.label.substring(1, header.label.length)}
                  </span>
                </div>
              )
            },
            dateHeader: (dateHeader) => {
              if (isToday(dateHeader.date))
                return (
                  <div>
                    <span className="bg-primary text-white rounded-full p-1">
                      {dateHeader.label}
                    </span>
                  </div>
                )
              return <div>{dateHeader.label}</div>
            },
            event: () => {
              return (
                <div className="text-center bg-transparent">
                  <div className="bg-marine-300 m-auto p-auto w-2 h-2 rounded-full">
                    &nbsp;
                  </div>
                </div>
              )
            },
          },
        }}
        views={['month']}
        formats={{
          weekdayFormat: (date, culture, localizer) => {
            const day = localizer?.format(date, 'eeee', culture) || ''
            return culture === 'nl-NL'
              ? day
              : day.charAt(0).toUpperCase() + day.slice(1)
          },
        }}
        eventPropGetter={eventStyleGetter}
        culture={t('locale')}
        onSelectEvent={(event: CalendarEvent) => setSelectedDay(event.start)}
        tooltipAccessor={(event) => event.data.name}
      />
    </div>
  )
}
