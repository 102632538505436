import { AlphaCalendar, CalendarEvent } from '../components/calendar'
import {
  Calendar as CalendarIcon,
  Package as PackageIcon,
} from '@phosphor-icons/react'
import { format, parseISO } from 'date-fns'
import { useState } from 'react'
import { Campaign } from '../../../server/src/graphql'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isCampaign = (value: any): value is Campaign => {
  return value.__typename === 'Campaign'
}

export default function AgendaPage() {
  const { t } = useTranslation()
  const [agenda, setAgenda] = useState<CalendarEvent>()

  const agendaView = (event: CalendarEvent) => {
    return (
      <div className="py-4 space-y-3">
        <div className="flex ">
          <div
            className={cx('rounded-2xl h-9 w-1.5 text-black-1', {
              'bg-marine-300': event.type === 'campaign',
              'bg-green-50': event.type === 'holiday',
            })}
          ></div>
          <p className="text-black-3 text-3xl font-bold overflow-hidden ml-2">
            {event.data.name}
          </p>
        </div>

        <div className="space-y-1">
          <div className="font-bold">{t('details')}</div>
          <div className="flex gap-x-2">
            <CalendarIcon size={16} weight="fill" className="self-center" />
            <p>
              {`${format(
                parseISO(event.data.startDate),
                'dd/MM/yyyy',
              )} - ${format(parseISO(event.data.endDate), 'dd/MM/yyyy')}`}
            </p>
          </div>
          {isCampaign(event.data) && (
            <div className="flex gap-x-2">
              <PackageIcon size={16} weight="fill" className="self-center" />
              {event.data.details}
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="bg-ui1 p-4 gap-4 space-y-2 flex flex-col h-full">
      <div className="bg-white px-8 rounded-md">
        {agenda && agendaView(agenda)}
      </div>
      <div className="p-4 sm:rounded-lg bg-white box-border h-full">
        <AlphaCalendar
          setAgenda={(event) => setAgenda(event)}
          className="w-4/5 flex-1 content-center mx-auto alphaCalendar"
        />
      </div>
    </div>
  )
}
