import { DashboardTile } from '../components/navbar/dashboardTile'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Calendar, Gauge, Sparkle, Table } from '@phosphor-icons/react'

export default function ActionPanel() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className="flex flex-col justify-around  h-screen place-items-center bg-ui1 items-center align-middle">
      <div className="item-center">
        <div className="text-center mb-20">
          <h1 className="text-55xl my-0 leding-none py-0 b-0 font-serif">
            {t('navigation.action_panel')}
          </h1>
          <p className="text-xl text-black-3">{t('navigation.get_started')}</p>
          <p className="text-xl text-black-3">
            {t('navigation.get_started_2')}
          </p>
        </div>
        <div className="grid grid-cols-2 grid-rows-2 gap-y-4 lg:grid-cols-4 w-2xl">
          <DashboardTile
            onClick={() => navigate('campaigns/list')}
            Icon={Table}
            groupSize="big"
            text={t('navigation.campaign_list')}
            iconWeight="fill"
          />
          <DashboardTile
            onClick={() => navigate('campaigns/create')}
            Icon={Sparkle}
            groupSize="big"
            text={t('navigation.create_campaign')}
            iconWeight="fill"
            isRounded={true}
          />
          <DashboardTile
            onClick={() => navigate('dashboard')}
            Icon={Gauge}
            groupSize="big"
            text={t('navigation.dashboard')}
            iconWeight="fill"
            isRounded={true}
          />
          <DashboardTile
            onClick={() => navigate('agenda')}
            Icon={Calendar}
            groupSize="big"
            text={t('navigation.agenda')}
            iconWeight="fill"
          />
        </div>
      </div>
    </div>
  )
}
