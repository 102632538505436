import { FormProps } from '@sourcelabbg/form/lib'
import { RadioGroupTypeInput } from './custom-types'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Controller } from 'react-hook-form'

export default function RadioGroupType({
  field,
  formProps,
}: {
  field: RadioGroupTypeInput
  formProps: FormProps
}) {
  const { id } = useParams()
  const { t } = useTranslation()

  return (
    <Controller
      control={formProps.control}
      name={field.name}
      render={({ field: { onChange, value } }) => {
        return (
          <div className="grid grid-cols-2 gap-4 my-2">
            <label
              htmlFor={`${field.name}-offline`}
              onClick={() => onChange('offline')}
              className={cx(
                'flex cursor-pointer rounded-lg border-2 bg-white p-2 shadow-sm focus:outline-none hover:border-secondary',
                `${value === 'offline' && 'border-black-1'}`,
                `${
                  id && value === 'online'
                    ? 'pointer-events-none opacity-50'
                    : ''
                }`,
              )}
            >
              <input
                {...formProps.register(field.name)}
                type="radio"
                value="offline"
                checked={value === 'offline'}
                className="sr-only"
                id={`${field.name}-offline`}
                data-testid={`${field.uiOptions?.testId}-offline`}
                disabled={id ? true : false}
              />
              <span className="flex flex-col text-md text-gray-2">
                <span className="font-bold">
                  {t('campaign_details.offline')}
                </span>
                <span className="font-medium">
                  {t('campaign_details.offline_description')}
                </span>
              </span>
            </label>
            <label
              htmlFor={`${field.name}-online`}
              onClick={() => onChange('online')}
              className={cx(
                'flex cursor-pointer rounded-lg border-2 bg-white p-2 shadow-sm focus:outline-none hover:border-secondary',
                `${value === 'online' && 'border-black-1'}`,
                `${
                  id && value === 'offline'
                    ? 'pointer-events-none  opacity-50'
                    : ''
                }`,
              )}
            >
              <input
                {...formProps.register(field.name)}
                type="radio"
                value="online"
                checked={value === 'online'}
                className="sr-only"
                id={`${field.name}-online`}
                data-testid={`${field.uiOptions?.testId}-online`}
                disabled={id ? true : false}
              />
              <span className="flex flex-col text-md text-gray-2">
                <span className="font-bold">
                  {t('campaign_details.online')}
                </span>
                <span className="font-medium">
                  {t('campaign_details.online_description')}
                </span>
              </span>
            </label>
          </div>
        )
      }}
    />
  )
}
