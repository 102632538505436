import { useQuery } from '@apollo/client'
import { Copy } from '@phosphor-icons/react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CAMPAIGN_PRESIGNED_URL from '../graphql/campaign/queries/presigned-url'

export type PrintFileDownloadModalProps = {
  downloadLink: string
  password: string
  onClose: () => void
}

export const PrintFileDownloadModal: FC<PrintFileDownloadModalProps> = ({
  downloadLink,
  password,
  onClose,
}) => {
  const { data, loading } = useQuery(CAMPAIGN_PRESIGNED_URL, {
    variables: { filePath: downloadLink },
  })

  const [showCopyToClipboardTooltip, setShowCopyToClipboardTooltip] =
    useState(false)
  const { t } = useTranslation()
  const handleCopyToClipboard = () => {
    window.navigator.clipboard.writeText(password)
    setShowCopyToClipboardTooltip(true)
    setTimeout(() => {
      setShowCopyToClipboardTooltip(false)
    }, 2000)
  }
  return (
    <div className="absolute  h-screen">
      <div className="fixed top-0 left-0 z-30 w-full h-screen bg-ui1 opacity-75"></div>
      <div className="flex justify-center fixed top-0 left-0 z-40 w-full h-screen align-center items-center overflow-scroll">
        <div className="flex flex-col absolute items-start p-8 z-50 bg-white rounded-md shadow-md w-[500px] h-fit">
          <div className="w-full flex flex-col">
            <h2 className="text-gray-900 leading-6 text-lg font-bold mb-2">
              {t('print_files_download.modal_title')}
            </h2>
            <p className="text-sm font-normal text-gray-500 mb-4">
              {t('print_files_download.modal_description')}
            </p>
            <p className="flex justify-between bg-ui1 rounded-lg p-4 mb-6 text-gray-1 text-xl font-bold">
              <span className="self-center">{password}</span>
              <span className="relative">
                <Copy
                  size={32}
                  weight="fill"
                  className="cursor-pointer"
                  onClick={handleCopyToClipboard}
                />
                {showCopyToClipboardTooltip && (
                  <span className="bg-gray-800 text-white text-center whitespace-nowrap rounded-lg p-2 absolute z-10 top-0 right-0 transform -translate-y-12">
                    {t('print_files_download.copied')}
                  </span>
                )}
              </span>
            </p>
            <div className="flex justify-end">
              <button
                className="py-3 px-6 border-primary border rounded-md text-primary font-normal leading-6 mr-2"
                onClick={onClose}
              >
                {t('close')}
              </button>
              {(loading && t('loading')) || (
                <a
                  className="py-3 px-6 bg-primary rounded-md text-white font-normal leading-6"
                  download
                  href={data?.presignedUrl?.url}
                >
                  {t('print_files_download.download')}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
