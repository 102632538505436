import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { ReactNode } from 'react'

type TooltipProps = {
  child: ReactNode
  text: string
}

export default function Tooltip({ child, text }: TooltipProps) {
  return (
    <TooltipPrimitive.TooltipProvider delayDuration={1}>
      <TooltipPrimitive.Tooltip>
        <TooltipPrimitive.TooltipTrigger asChild>
          {child}
        </TooltipPrimitive.TooltipTrigger>
        <TooltipPrimitive.TooltipContent>
          <p className="bg-black-1 text-white px-2 py-1 text-sm rounded-full">
            {text}
          </p>
        </TooltipPrimitive.TooltipContent>
      </TooltipPrimitive.Tooltip>
    </TooltipPrimitive.TooltipProvider>
  )
}
