import { Calendar, ToolbarProps, dateFnsLocalizer } from 'react-big-calendar'
import { format, getDay, isToday, parse, startOfWeek } from 'date-fns'
import nl from 'date-fns/locale/nl'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import HOLIDAYS_QUERY from '../../graphql/campaign/queries/holidays'
import cx from 'classnames'
import { Campaign, Holiday } from '../../../../server/src/graphql'

import {
  ArrowLeft as ChevronLeftIcon,
  ArrowRight as ChevronRightIcon,
} from '@phosphor-icons/react'
import { enGB } from 'date-fns/locale'
import { createPortal } from 'react-dom'
import DayPreview from '@/pages/campaign/campaign-details/day-preview'

const locales = {
  'en-US': enGB,
  'nl-NL': nl,
}

export type Agenda = {
  name: string
  startDate: string
  endDate: string
  type: string
  data: Holiday | Campaign
  details: string
  continuesAfter: boolean
  continuesPrior: boolean
}

export type CalendarEvent = {
  title: JSX.Element
  start: string
  end: string
  type: string
  data: Agenda
}

type HolidayResponse = {
  campaigns: Agenda[]
  holidays: Agenda[]
}

type CalendarProps = {
  setAgenda?: (_event: CalendarEvent) => void
  className?: string
}

type SelectedEvents = {
  events: CalendarEvent[]
  date: Date
}

export const AlphaCalendar = ({
  setAgenda,
  className = 'w-full',
}: CalendarProps) => {
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  })

  const { t } = useTranslation()
  const { data, loading } = useQuery<HolidayResponse>(HOLIDAYS_QUERY)
  const [showModal, setShowModal] = useState(false)
  const [selectedEvents, setSelectedEvents] = useState<SelectedEvents>()

  useEffect(() => {
    if (selectedEvents) {
      setShowModal(true)
    }
  }, [selectedEvents])

  const toEvent = (styleType: string) => (event: Agenda) => {
    return (
      {
        title: (
          <div
            className={cx(
              'flex items-center py-0.5 rounded-full h-5 px-1 mx-0',
              {
                'bg-marine-300': styleType === 'campaign',
                'text-black-1': styleType === 'holiday',
                'bg-green-50': styleType === 'holiday',
              },
            )}
          >
            <p
              className={cx(
                'text-ellipsis overflow-hidden truncate text-black-1 px-2 text-sm',
                {
                  'text-white': styleType === 'campaign',
                },
              )}
              id="title"
            >
              {event.name}
            </p>
          </div>
        ),
        start: event.startDate,
        end: event.endDate,
        type: styleType,
        data: event,
      } || {}
    )
  }

  const events = useMemo(
    () => [
      ...(data?.holidays?.map(toEvent('holiday')) || []),
      ...(data?.campaigns?.map(toEvent('campaign')) || []),
    ],
    [data],
  )

  const Portal = () => {
    return (
      <>
        {showModal &&
          selectedEvents &&
          createPortal(
            <DayPreview
              events={selectedEvents.events}
              selectedDay={selectedEvents.date.toISOString()}
              onClose={() => setShowModal(false)}
            />,
            document.body,
          )}
      </>
    )
  }
  if (loading) return <div>{t('loading')}</div>

  const eventStyleGetter = () => ({
    style: { backgroundColor: 'white' },
  })

  const toolbar = (
    toolbar: ToolbarProps<{
      title: Element
      start: string
      end: string
      type: string
      data: Agenda
    }>,
  ) => {
    const currentMonthBtnStyle =
      toolbar?.date.getMonth() === new Date().getMonth()
        ? 'border-primary text-primary cursor-default'
        : 'bg-primary text-white hover:bg-marine-300'

    return (
      <header className="bg-grey-100 flex items-center justify-between border-b border-gray-200 px-6 py-4 lg:flex-none">
        <div className="flex items-center">
          <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
            <button
              type="button"
              className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon
                className="h-5 w-5"
                aria-hidden="true"
                onClick={() => toolbar.onNavigate('PREV')}
              />
            </button>
            <button
              type="button"
              className="hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block w-40"
            >
              {toolbar.label}
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              type="button"
              className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon
                className="h-5 w-5"
                aria-hidden="true"
                onClick={() => toolbar.onNavigate('NEXT')}
              />
            </button>
          </div>
        </div>
        <div className="hidden md:ml-4 md:flex md:items-center">
          <button
            type="button"
            className={cx(
              currentMonthBtnStyle,
              'border ml-6 rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-marine-300',
            )}
            onClick={() => toolbar.onNavigate('TODAY')}
          >
            {t('current_month')}
          </button>
        </div>
      </header>
    )
  }
  return (
    <div className={cx('h-full lg:flex lg:h-full lg:flex-col', className)}>
      <Portal />
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        components={{
          toolbar,
          timeGutterWrapper: () => <div>1</div>,
          month: {
            header: (header) => {
              return (
                <div className="py-2">
                  {header.label.charAt(0)}
                  <span className="sr-only sm:not-sr-only">
                    {header.label.substring(1, header.label.length)}
                  </span>
                </div>
              )
            },
            dateHeader: (dateHeader) => {
              if (isToday(dateHeader.date))
                return (
                  <div>
                    <span className="bg-primary text-white rounded-full p-1">
                      {dateHeader.label}
                    </span>
                  </div>
                )
              return <div>{dateHeader.label}</div>
            },
            event: (event) => {
              if (!event.continuesAfter && !event.continuesPrior) {
                return <div className="bg-white m-0 p-0">{event.title}</div>
              }
              return <div className="rounded-lg m-0 p-0">{event.title}</div>
            },
          },
        }}
        views={['month']}
        formats={{
          weekdayFormat: (date, culture, localizer) => {
            const day = localizer?.format(date, 'eeee', culture) || ''
            return culture === 'nl-NL'
              ? day
              : day.charAt(0).toUpperCase() + day.slice(1)
          },
        }}
        eventPropGetter={eventStyleGetter}
        showAllEvents={false}
        onShowMore={(events, date) => {
          setSelectedEvents({ events, date })
        }}
        culture={t('locale')}
        messages={{
          next: '>',
          previous: '<',
        }}
        onSelectEvent={setAgenda}
        tooltipAccessor={(event) => event.data.name}
        className="h-full"
      />
    </div>
  )
}
