import { gql } from '@apollo/client'
import GetIncentiveItemFragment from '../fragments/get-incentive-item'

export default gql`
  ${GetIncentiveItemFragment}
  query IncentiveById($id: ID) {
    incentiveById(id: $id) {
      ...GetIncentiveItemFragment
    }
  }
`
