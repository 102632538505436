// React component with inputs to specify which fields in the CSV should be parsed
import { CheckCircle, XCircle } from '@phosphor-icons/react'
import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import tailwindConfig from '../../../tailwind.config'
import { CsvFieldsMapping } from './csv-field-mapping'

export type CSVFieldSelectorProps = {
  fieldsMapping: CsvFieldsMapping
  mappedFields: { [field: string]: boolean | undefined }
  onFieldsMappingChanged: (fields: CsvFieldsMapping) => void
}

const CSVFieldSelector: FC<CSVFieldSelectorProps> = ({
  fieldsMapping,
  mappedFields,
  onFieldsMappingChanged,
}) => {
  const { t } = useTranslation()
  const csvFieldsMappingFormOnChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLFormElement>) => {
      const formFieldMapping = event.target.value
      const formFieldName = event.target.name
      const fieldDefinition = {
        ...fieldsMapping[formFieldName],
        mappedName: formFieldMapping,
      }
      const newFormValue = {
        ...fieldsMapping,
        [formFieldName]: fieldDefinition,
      }
      onFieldsMappingChanged(newFormValue)
    },
    [fieldsMapping, onFieldsMappingChanged],
  )

  const isMapped = useCallback(
    (field: string) => mappedFields[field],
    [mappedFields],
  )

  return (
    <div className="flex flex-col items-center">
      <form
        onChange={csvFieldsMappingFormOnChangeHandler}
        className="w-full space-y-4"
      >
        {fieldsMapping &&
          Object.entries(fieldsMapping).map(
            ([field, { displayName, mappedName }]) => {
              return (
                <div key={field}>
                  <label
                    htmlFor={field}
                    className="block text-sm font-medium leading-6 text-black"
                  >
                    {t(displayName)}
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 right-2 flex items-center">
                      {isMapped(field) === undefined ? null : isMapped(
                          field,
                        ) ? (
                        <CheckCircle
                          size={24}
                          color={tailwindConfig.theme.extend.colors.primary}
                          weight="fill"
                        />
                      ) : (
                        <XCircle
                          size={24}
                          // TODO: Change color to error color
                          color={tailwindConfig.theme.extend.colors.secondary}
                          weight="fill"
                        />
                      )}
                    </div>

                    <input
                      type="text"
                      name={field}
                      defaultValue={mappedName}
                      className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-7"
                      placeholder={t('end_users.map_field_placeholder')}
                    />
                  </div>
                </div>
              )
            },
          )}
      </form>
    </div>
  )
}

export default CSVFieldSelector
