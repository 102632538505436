import { useAuth0 } from '@auth0/auth0-react'
import i18n from '../i18n'
import { Button } from './button'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'
import { Popover, PopoverContent, PopoverTrigger } from './popover'
import { env } from '../env.ts'
import { CaretDown, SignOut } from '@phosphor-icons/react'
import cx from 'classnames'

export const LanguageSettings = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const language = i18n.language

  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const { logout, user } = useAuth0()
  const className =
    'border-0 border-transparent hover:border-transparent focus:outline-none focus:ring-transparent w-auto'

  return (
    <Popover open={isOpen}>
      <PopoverTrigger asChild>
        <div
          onClick={() => setIsOpen(true)}
          className="flex flex-row gap-2 items-center cursor-pointer"
        >
          <span className="whitespace-nowrap text-sm">{`${t(
            'navigation.hi',
          )}, ${user?.nickname}`}</span>
          <button className="w-10 rounded-full p-0">
            <CaretDown weight="fill" className="h-4 w-4" />
            <span className="sr-only">Open popover</span>
          </button>
        </div>
      </PopoverTrigger>
      <PopoverContent className={cx('w-auto bg-white flex flex-col')}>
        <div className="space-y-2 py-1" ref={ref}>
          <div>
            {language === 'en' ? (
              <Button
                label={t('languages.nl')}
                size="small-icon"
                className={className}
                onClick={() => {
                  i18n.changeLanguage('nl')
                  setIsOpen(false)
                }}
              />
            ) : (
              <Button
                label={t('languages.en')}
                size="small-icon"
                className={className}
                onClick={() => {
                  i18n.changeLanguage('en')
                  setIsOpen(false)
                }}
              />
            )}
          </div>
          <div>
            <Button
              label={t('navigation.log_out')}
              IconLeft={SignOut}
              size="small-icon"
              className="border-0 border-transparent hover:border-transparent focus:outline-none focus:ring-transparent "
              onClick={() => {
                logout({
                  logoutParams: {
                    returnTo: env.VITE_AUTH0_REDIRECT_URL,
                  },
                })
              }}
            />
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}
