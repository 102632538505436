import { useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { SubmitHandler } from 'react-hook-form'
import { useSetRecoilState } from 'recoil'
import UPDATE_CAMPAIGN from '../../../graphql/campaign/mutations/update-details'
import { activeStepState } from '../../../store/atoms/active-step'
import {
  UpdateCampaignDetailsType,
  CreateCampaignSchema,
  CampaignDetailsFormSchema,
} from '@/schema/campaign-details.schema'
import CampaignDetailsForm from './form'
import { GetCampaignFragment } from '../../../graphql/campaign/fragments/get-campaign'

export default function UpdateCampaignDetails() {
  const { id } = useParams()
  const { campaign } = useOutletContext<{ campaign: GetCampaignFragment }>()

  const [updateCampaign] = useMutation(UPDATE_CAMPAIGN, {})
  const setActiveStep = useSetRecoilState(activeStepState)
  const navigate = useNavigate()
  useEffect(() => setActiveStep(1), [setActiveStep])
  if (!id || !campaign) return <p>Missing id</p>

  const onSubmit: SubmitHandler<UpdateCampaignDetailsType> = (data, e) => {
    const buttonName = (
      (e?.nativeEvent as SubmitEvent) || undefined
    )?.submitter?.getAttribute('name')
    updateCampaign({
      variables: {
        campaign: {
          ...data,
          id,
        },
      },
      onCompleted(data) {
        if (buttonName === 'next') {
          navigate(`/campaigns/end-users/${data.updateCampaignDetails.id}`)
        }
      },
    })
  }

  return (
    <CampaignDetailsForm
      values={CampaignDetailsFormSchema.parse(campaign)}
      onSubmit={onSubmit}
      schema={CreateCampaignSchema}
    />
  )
}
