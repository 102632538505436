import { gql } from '@apollo/client'
import GetCampaignFragment from '../fragments/get-campaign'

export default gql`
  ${GetCampaignFragment}
  query GetCampaign($id: ID!) {
    campaign(id: $id) {
      ...GetCampaignFragment
    }
  }
`
