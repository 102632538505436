import { gql } from '@apollo/client'

export default gql`
  fragment GetIncentiveItemFragment on Incentive {
    id
    campaignId
    customerId
    name
    price
    description
    isAvailable
    picture
    type
    skuCode
    digitalCode
    categoryId
    isActive
    categories {
      id
      incentiveCategory {
        id
        name
      }
    }
    isAttachedToLotteryCampaign
    incentiveAttachedToCampaign
    isClaimed
    files {
      id
      s3Key
      url
      aggregateId
      fileType
      fileVariant
    }
  }
`
