import { gql } from '@apollo/client'

export default gql`
  query endUsersPerCampaign($campaignId: ID!) {
    endUsersPerCampaign(campaignId: $campaignId) {
      general {
        id
        campaignId
        campaignIncentiveId
        isInvited
        referralOf
        questionnaireAnswers
        happinessAnswers
      }
      referral {
        id
        campaignId
        campaignIncentiveId
        isInvited
        referralOf
        questionnaireAnswers
        happinessAnswers
      }
    }
  }
`
