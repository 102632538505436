import { useParams, useNavigate, useOutletContext } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { activeStepState } from '../../store/atoms/active-step'
import { useEffect, useState } from 'react'
import { Switch } from '@/components/switch'
import SET_HAPPINESS_CHECK_FORM from '../../graphql/campaign/mutations/set-happiness-check-form'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { RadioGroupHappinessInput } from '../../components/form/custom-types'
import AlphaForm from '../../components/form'
import { GetCampaignFragment } from '../../graphql/campaign/fragments/get-campaign'
import CampaignButtons from '../../components/campaign-buttons'

export default function HappinessCheck() {
  const { id } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { campaign } = useOutletContext<{ campaign: GetCampaignFragment }>()
  const setActiveStep = useSetRecoilState(activeStepState)
  const happinessCheckForm: RadioGroupHappinessInput[] = [
    {
      name: 'happinessCheck',
      type: 'custom',
      customControlType: 'radioGroupHappiness',
      iconTheme: 'faces',
      options: [
        {
          name: t('happiness_check.really_negative'),
          value: '1',
          Icon: 'bad',
        },

        {
          name: t('happiness_check.negative'),
          value: '2',
          Icon: 'belowAverage',
        },
        {
          name: t('happiness_check.alright'),
          value: '3',
          Icon: 'average',
        },
        {
          name: t('happiness_check.positive'),
          value: '4',
          Icon: 'aboveAverage',
        },
        {
          name: t('happiness_check.really_positive'),
          value: '5',
          Icon: 'good',
        },
      ],
    },
  ]
  const [selectedHappinessCheckForm, setSelectedHappinessCheckForm] = useState<
    RadioGroupHappinessInput[] | undefined
  >(campaign.completedStep > 7 ? campaign.happinessCheck : happinessCheckForm)

  const [setHappinessCheckForm] = useMutation(SET_HAPPINESS_CHECK_FORM, {})

  useEffect(() => setActiveStep(8), [setActiveStep])

  const onSubmit = (draft: boolean) => {
    setHappinessCheckForm({
      variables: {
        happinessCheck: {
          id,
          happinessCheck: selectedHappinessCheckForm,
        },
      },
      onCompleted() {
        if (!draft) navigate(`/campaigns/confirmation/${id}`)
      },
    })
  }

  return (
    <div className="w-full m-auto px-2">
      <h1>{t('happiness_check.module')}</h1>
      <div className="flex gap-4 justify-start items-center py-4">
        <Switch
          defaultChecked={selectedHappinessCheckForm !== null}
          onCheckedChange={(showHappinessCheck) =>
            setSelectedHappinessCheckForm(
              showHappinessCheck ? happinessCheckForm : undefined,
            )
          }
        />
        <p>{t('happiness_check.include')}</p>
      </div>
      {selectedHappinessCheckForm && (
        <div className="bg-ui1 items-center justify-center py-12 min-w-[640px]">
          <AlphaForm
            fields={[{ ...selectedHappinessCheckForm[0], disabled: true }]}
          />
        </div>
      )}

      <CampaignButtons
        onSaveDraft={() => onSubmit(true)}
        onSubmit={() => onSubmit(false)}
        submitLabel={t('save_campaign')}
      />
    </div>
  )
}
