import React from 'react'
import { Link, NavLink, useMatch, useNavigate } from 'react-router-dom'
import { IconWithShadow } from './iconWithShadow'
import { Calendar, Gauge, Gift, Sparkle, Table } from '@phosphor-icons/react'
import { Button } from './button'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { LanguageSettings } from './language-settings'
import logo from '../assets/images/alphabait_logo.svg'

const Navigation: React.FC = () => {
  const hideMenu = [useMatch('/')].some(Boolean)

  const navigate = useNavigate()
  const { t } = useTranslation()
  const navLinkClasses = (active: boolean) =>
    classNames([
      'flex',
      'flex-row',
      'px-2',
      'pb-3',
      'pt-1',
      'gap-4',
      'hover:bg-ui1',
      'hover:rounded-md',
      { 'bg-ui1': active, 'rounded-md': active },
    ])

  return (
    <nav
      id="header"
      className="flex px-4 py-3 justify-between items-center w-full top-0 rounded-b-md shadow-md"
    >
      <div
        className="w-full items-center flex
      2"
      >
        <div className="pl-4 md:pl-0 py-2">
          <Link to="/">
            <img className="w-8 h-7 mr-9" src={logo} alt="alphabait logo" />
          </Link>
        </div>

        {!hideMenu && (
          <div className="flex text-sm text-black-1 gap-6 px-4">
            <NavLink
              to="/dashboard"
              className={({ isActive }) => {
                return navLinkClasses(isActive)
              }}
            >
              <>
                <IconWithShadow
                  Icon={Gauge}
                  iconWeight="fill"
                  groupSize="small"
                  isRounded={true}
                />
                <span className="pt-2">{t('navigation.dashboard')}</span>
              </>
            </NavLink>
            <NavLink
              to="/campaigns/list"
              className={({ isActive }) => {
                return navLinkClasses(isActive)
              }}
            >
              <>
                <IconWithShadow
                  Icon={Table}
                  iconWeight="fill"
                  groupSize="small"
                />
                <span className="pt-2">{t('navigation.campaign_list')}</span>
              </>
            </NavLink>
            <NavLink
              to="/agenda"
              className={({ isActive }) => {
                return navLinkClasses(isActive)
              }}
            >
              <>
                <IconWithShadow
                  Icon={Calendar}
                  iconWeight="fill"
                  groupSize="small"
                />
                <span className="pt-2">{t('navigation.agenda')}</span>
              </>
            </NavLink>
            <NavLink
              to="/incentives"
              className={({ isActive }) => {
                return navLinkClasses(isActive)
              }}
            >
              <>
                <IconWithShadow
                  Icon={Gift}
                  iconWeight="fill"
                  groupSize="small"
                />
                <span className="pt-2">{t('navigation.incentives')}</span>
              </>
            </NavLink>
          </div>
        )}
      </div>
      <div className="flex flex-row gap-4">
        {!hideMenu && (
          <Button
            primary={true}
            size="small-icon"
            label={t('navigation.create_campaign')}
            IconLeft={Sparkle}
            iconWeight="fill"
            onClick={() => navigate('campaigns/create')}
            className="border-0 pb-4"
          />
        )}

        <div className="flex flex-row gap-2 items-center">
          <LanguageSettings />
        </div>
      </div>
    </nav>
  )
}

export default Navigation
