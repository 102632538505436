import { gql } from '@apollo/client'

export default gql`
  query EndUsers($campaignId: String!) {
    endUsers(campaignId: $campaignId) {
      id
      firstName
      middle
      lastName
      email
      address
      birthday
      campaignId
      campaignIncentiveId
      phoneNumber
      sku
      digitalCode
      uniqueCode
    }
  }
`
