import { AlphaTypes } from '../form/custom-types'
import i18n from 'i18next'

export default [
  {
    name: 'recommendToFriend',
    type: 'custom',
    customControlType: 'range',
    uiOptions: { label: i18n.t('optional_question.question_placeholder') },
  },
  {
    name: 'describeSatisfaction',
    type: 'custom',
    customControlType: 'radioGroupSurvey',
    options: [
      { name: 'slecht', value: 'poor' },
      { name: 'OK', value: 'okay' },
      { name: 'Goed', value: 'good' },
      { name: 'Geweldig', value: 'great' },
    ],
    uiOptions: {
      label: 'Hoe zou u uw ervaring met ons team omschrijven?',
      labelClassName: 'text-md text-theme-primary',
    },
  },
  {
    name: 'importantToCustomer',
    type: 'custom',
    customControlType: 'radioGroupSurvey',
    options: [
      { name: 'Duurzaamheid', value: 'sustainability' },
      { name: 'Prijs', value: 'price' },
      { name: 'Service', value: 'service' },
    ],
    uiOptions: {
      label: 'Wat is voor u het belangrijkst?',
      labelClassName: 'text-md text-theme-primary',
    },
  },
] as AlphaTypes[]
