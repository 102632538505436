import { gql } from '@apollo/client'

export default gql`
  query campaignPerformance($campaignId: ID!) {
    campaignPerformance(campaignId: $campaignId) {
      general {
        action
        count
      }
      referral {
        action
        count
      }
    }
  }
`
