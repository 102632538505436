import { gql } from '@apollo/client'
import GetCampaignFragment from '../fragments/get-campaign'

export default gql`
  ${GetCampaignFragment}
  mutation UpdateCampaignDetails($campaign: UpdateCampaignDetailsInput) {
    updateCampaignDetails(campaign: $campaign) {
      ...GetCampaignFragment
    }
  }
`
