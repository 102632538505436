import { FormProps } from '@sourcelabbg/form/lib'
import { RadioGroupHappinessInput } from './custom-types'
import { useState } from 'react'
import {
  SmileySad,
  SmileyBlank,
  SmileyMeh,
  Smiley,
  SmileyWink,
} from '@phosphor-icons/react'
import style from './form.module.css'
import cx from 'classnames'

const iconThemes: Record<string, Record<string, JSX.Element>> = {
  faces: {
    bad: <SmileySad className={style.happinessIcons} />,
    belowAverage: <SmileyBlank className={style.happinessIcons} />,
    average: <SmileyMeh className={style.happinessIcons} />,
    aboveAverage: <Smiley className={style.happinessIcons} />,
    good: <SmileyWink className={style.happinessIcons} />,
  },
}

export default function RadioGroupHappiness({
  field,
  formProps,
}: {
  field: RadioGroupHappinessInput
  formProps: FormProps
}) {
  const [isSelected, setIsSelected] = useState<string>()

  return (
    <div
      className={cx(style.radioInputHappiness, style.cursorDefault, 'm-auto')}
    >
      {field.options &&
        field.options.map((option) => {
          return (
            <label
              htmlFor={`${field.name}-${option.name}`}
              onClick={() => {
                setIsSelected(option.value)
                formProps.setValue(field.name, option.value)
              }}
              className={cx(style.radioInputHappinessLabel, {
                [style.selected]:
                  !field.disabled && isSelected === option.value,

                [style.cursorDefault]: field.disabled,
              })}
              key={`${field.name}-${option.name}`}
            >
              <input
                {...formProps.register(field.name, { value: field.value })}
                type="radio"
                value={option.value}
                checked={Number(field.value) === Number(option.value)}
              />
              {option.Icon && (
                <div className={style.icon}>
                  <div className={style.iconElement}>
                    <div>{iconThemes[field.iconTheme][option.Icon!]}</div>
                  </div>
                  <span className="relative pt-6 w-full text-center">
                    {option.name}
                  </span>
                </div>
              )}
            </label>
          )
        })}
    </div>
  )
}
