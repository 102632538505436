import { z } from 'zod'

const envSchema = z.object({
  VITE_AUTH0_DOMAIN: z.string(),
  VITE_AUTH0_CLIENT_ID: z.string(),
  VITE_AUTH0_REDIRECT_URL: z.string(),
  VITE_S3_URL: z.string(),
  VITE_PUBLIC_URL: z.string(),
})
const envSettings = {
  VITE_AUTH0_DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN,
  VITE_AUTH0_CLIENT_ID: import.meta.env.VITE_AUTH0_CLIENT_ID,
  VITE_AUTH0_REDIRECT_URL: import.meta.env.VITE_AUTH0_REDIRECT_URL,
  VITE_S3_URL: import.meta.env.VITE_S3_URL,
  VITE_PUBLIC_URL: import.meta.env.VITE_PUBLIC_URL,
}
const valid = envSchema.safeParse(envSettings)
if (!valid.success) {
  console.error(valid.error)
}

export const env = envSchema.parse(envSettings)
