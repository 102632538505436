import { FormProps } from '@sourcelabbg/form/lib'
import { Controller } from 'react-hook-form'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { WYSIWYGInput } from './custom-types'

export type WYSIWYGProps = {
  field: WYSIWYGInput
  formProps: FormProps
}

const WYSIWYG = ({ field, formProps }: WYSIWYGProps) => {
  const modules = {
    toolbar: false,
  }

  const stripHtmlTags = (html: string) => {
    const div = document.createElement('div')
    div.innerHTML = html
    return div.textContent || div.innerText || ''
  }
  const maxChars = field.maxChars
  return (
    <>
      <Controller
        control={formProps.control}
        name={field.name}
        render={({ field: { value, onChange } }) => {
          const plainTextContent = stripHtmlTags(
            formProps.formValues[field.name],
          )

          return (
            <>
              <ReactQuill
                value={value}
                onChange={(content) => {
                  const emptyContentRegex = /^<p><br><\/p>$/
                  const sanitizedContent = emptyContentRegex.test(content)
                    ? ''
                    : content

                  const plainTextContent = stripHtmlTags(sanitizedContent)
                  if (maxChars && plainTextContent.length > maxChars) {
                    let currentPlainTextLength = 0
                    const parser = new DOMParser()
                    const doc = parser.parseFromString(
                      sanitizedContent,
                      'text/html',
                    )
                    const walker = document.createTreeWalker(
                      doc.body,
                      NodeFilter.SHOW_TEXT,
                    )

                    while (walker.nextNode()) {
                      const node = walker.currentNode
                      if (node.textContent) {
                        const remainingChars = maxChars - currentPlainTextLength
                        if (node.textContent.length <= remainingChars) {
                          currentPlainTextLength += node.textContent.length
                        } else {
                          node.textContent = node.textContent.substring(
                            0,
                            remainingChars,
                          )

                          break
                        }
                      }
                    }

                    const serializer = new XMLSerializer()
                    const truncatedHtmlContent = serializer.serializeToString(
                      doc.body,
                    )

                    onChange(truncatedHtmlContent)
                  } else if (!maxChars) {
                    onChange(sanitizedContent)
                  }
                }}
                modules={modules}
                theme="snow"
                className={field?.uiOptions?.className}
              />

              {maxChars && (
                <p className="text-xs text-right mt-2">
                  {maxChars - plainTextContent.length}{' '}
                  {maxChars -
                    stripHtmlTags(formProps.formValues[field.name]).length ===
                  1
                    ? 'character'
                    : 'characters'}{' '}
                  left
                </p>
              )}
            </>
          )
        }}
      />
    </>
  )
}

export default WYSIWYG
