import { useState } from 'react'
import { Pencil } from '@phosphor-icons/react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { UpdateIncentiveItemInput } from '@/gql'
import { defaultImage, displayS3File } from '../../../utils/s3'
import { Button } from '@/components/button'

type CardProps = {
  incentive: UpdateIncentiveItemInput
  onClick: () => void
  selectedIncentiveItems?: { id: string; skuCode?: string }[]
  onClickSelected: () => void
}

export const Card = ({
  incentive,
  onClick,
  selectedIncentiveItems,
  onClickSelected,
}: CardProps) => {
  const { t } = useTranslation()

  const [style, setStyle] = useState({ display: 'none' })
  const { name, price, description, id, picture } = incentive

  const selected = selectedIncentiveItems?.some((s) => s.id === id)
    ? {
        className:
          'bg-secondary text-white hover:bg-secondary focus:ring-0 focus:ring-offset-0',
        title: 'incentives.selected',
      }
    : {
        className:
          'text-secondary hover:text-white hover:bg-secondary focus:ring-0 focus:ring-offset-0',
        title: 'incentives.select',
      }

  return (
    <div
      key={id}
      className="flex flex-col bg-white border border-ui1 rounded-lg min-h-82 h-96 w-64"
    >
      <img
        className="mt-2 m-auto max-h-40 object-contain rounded-md"
        src={picture ? displayS3File(picture) : defaultImage}
      />
      <div
        className="p-2 flex flex-col h-full justify-between"
        key={`${id}`}
        style={{
          position: 'relative',
          textAlign: 'center',
        }}
        onMouseEnter={(_e) => {
          setStyle({ display: 'block' })
        }}
        onMouseLeave={(_e) => {
          setStyle({ display: 'none' })
        }}
      >
        <div className="flex flex-row gap-4 justify-between py-2">
          <span>{name}</span>
          <span className="whitespace-nowrap">
            <span className="font-bold">€{price}</span>
            <span className="text-gray-3"> /pcs</span>
          </span>
        </div>
        <span className="text-gray-3 text-sm">{description}</span>
        <Button
          style={style}
          size="small-icon"
          label={t('edit')}
          IconLeft={Pencil}
          iconWeight="fill"
          onClick={onClick}
          className="border-none m-auto my-0 p-2"
        />
        <button
          className={cx(
            'w-full rounded-md cursor-pointer border border-secondary hover:border-violet-800',
            selected.className,
          )}
          onClick={onClickSelected}
        >
          {t(selected.title)}
        </button>
      </div>
    </div>
  )
}
