import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { activeStepState } from '../../store/atoms/active-step'
import { useEffect, useReducer, useState } from 'react'
import AlphaForm from '../../components/form'
import questionnaires from '../../components/questionnaires'
import themes from '../../components/themes'
import { useMutation } from '@apollo/client'
import SAVE_QUESTIONNAIRE from '../../graphql/campaign/mutations/questionnaire'
import { Button } from '../../components/button'
import { useTranslation } from 'react-i18next'
import { createPortal } from 'react-dom'
import OpenQuestion from '../../components/open-question'
import { SubmitHandler } from 'react-hook-form'
import { GetCampaignFragment } from '../../graphql/campaign/fragments/get-campaign'
import theme1Image from '../../assets/images/theme1.png'
import theme2Image from '../../assets/images/theme2.png'
import CampaignButtons from '../../components/campaign-buttons'
import { AlphaTypes } from '@/components/form/custom-types'
import { X } from '@phosphor-icons/react'
import { isLayout } from '@sourcelabbg/form/lib'

type Questionnaire = {
  name?: string
  image?: string
  theme?: string
  form: AlphaTypes[]
}

export default function QuestionnaireComponent({
  referral,
}: {
  referral: boolean
}) {
  const { id } = useParams()
  const { t } = useTranslation()
  const { campaign } = useOutletContext<{ campaign: GetCampaignFragment }>()
  const setActiveStep = useSetRecoilState(activeStepState)
  const [showModal, setShowModal] = useState(false)
  const [isEdited, setIsEdited] = useState(false)
  const navigate = useNavigate()

  type ActionTypes = 'add' | 'remove' | 'reset'
  const [checkedIndices, dispatch] = useReducer(
    (
      state: Record<number, boolean>,
      action: { type: ActionTypes; questionIndex: number },
    ) => {
      const { questionIndex } = action
      switch (action.type) {
        case 'add':
          return { ...state, [questionIndex]: true }
        case 'remove':
          return { ...state, [questionIndex]: false }
        case 'reset':
          return {}
        default:
          return state
      }
    },
    {},
  )

  const withButton = (questionnaire: Questionnaire) => {
    const deleteQuestionnaireItem = (index: number) => {
      dispatch({
        type: 'remove',
        questionIndex: index,
      })
      const updatedQuestionnaire =
        questionnaire &&
        questionnaire?.form.filter((_, elementIndex) => elementIndex != index)
      setQuestionnaire(
        withButton({
          ...questionnaire,
          form: updatedQuestionnaire,
        }),
      )
    }

    return {
      ...questionnaire,
      form: questionnaire?.form.map((field: AlphaTypes, index) => {
        const isChecked = checkedIndices[index] ?? false

        if (
          (isLayout(field) &&
            !field.className?.search('productServiceWrapper')) ||
          (isLayout(field) && !field.className?.search('questionnaireWrapper'))
        ) {
          return {
            className: field.className,
            children: [
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              field?.children[0],
              {
                render: () => (
                  <div className="flex m-auto mr-10 text-sm items-center">
                    <p className="mr-2">{t('required')}</p>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={() => {
                        dispatch({
                          type: isChecked ? 'remove' : 'add',
                          questionIndex: index,
                        })
                      }}
                    />
                  </div>
                ),
              },
              {
                render: () => (
                  <button
                    type="button"
                    onClick={() => deleteQuestionnaireItem(index)}
                    className="mx-4"
                  >
                    <X size={22} className="self-center cursor-pointer" />
                  </button>
                ),
              },
            ],
          }
        }
        return {
          className: `${
            questionnaire.name === 'questionnaire.questionnaire_1.name'
              ? 'productServiceWrapper'
              : 'questionnaireWrapper'
          } my-2`,
          children: [
            { ...field },
            {
              render: () => (
                <div className="flex m-auto mr-10 text-sm items-center">
                  <p className="mr-2">{t('required')}</p>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => {
                      dispatch({
                        type: isChecked ? 'remove' : 'add',
                        questionIndex: index,
                      })
                    }}
                  />
                </div>
              ),
            },
            {
              render: () => (
                <button
                  type="button"
                  onClick={() => deleteQuestionnaireItem(index)}
                  className="mx-4"
                >
                  <X size={22} className="self-center cursor-pointer" />
                </button>
              ),
            },
          ],
        }
      }),
    }
  }

  const [currentQuestionnaire, setQuestionnaire] = useState<Questionnaire>()

  useEffect(() => {
    if (
      (currentQuestionnaire &&
        Object.values(checkedIndices)
          .slice(0, currentQuestionnaire?.form?.length - 1)
          .includes(true)) ||
      (currentQuestionnaire &&
        currentQuestionnaire.name === 'questionnaire.questionnaire_1.name' &&
        currentQuestionnaire.form.length !== 10) ||
      (currentQuestionnaire &&
        currentQuestionnaire.name === 'questionnaire.questionnaire_2.name' &&
        currentQuestionnaire.form.length !== 3)
    ) {
      setIsEdited(true)
    } else {
      setIsEdited(false)
    }
  }, [currentQuestionnaire])

  useEffect(() => {
    const fieldsWithValidations = currentQuestionnaire?.form.map(
      (field, index) =>
        isLayout(field) && checkedIndices[index]
          ? ({
              ...field,
              children: [{ ...field.children?.[0], validations: ['required'] }],
            } as AlphaTypes)
          : isLayout(field) && !checkedIndices[index]
          ? ({
              ...field,
              children: [{ ...field.children?.[0], validations: [] }],
            } as AlphaTypes)
          : field,
    )

    const formWithValidations = {
      ...currentQuestionnaire,
      form: fieldsWithValidations || [],
    }

    setQuestionnaire(withButton(formWithValidations))
  }, [checkedIndices])

  useEffect(() => {
    const questionnaire = referral
      ? campaign.referralQuestionnaire
      : campaign.questionnaire

    questionnaire?.form.map((field: AlphaTypes, index: number) => {
      if (
        isLayout(field) &&
        !isLayout(field.children?.[0]) &&
        field.children?.[0].validations?.includes('required')
      ) {
        dispatch({
          type: 'add',
          questionIndex: index,
        })
      } else {
        dispatch({
          type: 'remove',
          questionIndex: index,
        })
      }
    })

    if (referral) {
      return campaign.referralQuestionnaire
        ? setQuestionnaire(withButton(campaign.referralQuestionnaire))
        : setQuestionnaire(withButton(campaign.questionnaire))
    } else {
      return campaign.questionnaire
        ? setQuestionnaire(withButton(campaign.questionnaire))
        : setQuestionnaire(withButton(questionnaires[0]))
    }
  }, [campaign.questionnaire, campaign.referralQuestionnaire, referral])

  const [currentTheme, setCurrentTheme] = useState(
    campaign.questionnaire?.theme ?? themes[1],
  )

  const [saveQuestionnaire] = useMutation(SAVE_QUESTIONNAIRE, {})
  useEffect(() => setActiveStep(referral ? 4 : 3), [setActiveStep, referral])
  const onSubmit = (isDraft: boolean) => {
    currentQuestionnaire &&
      saveQuestionnaire({
        variables: {
          questionnaire: {
            id,
            questionnaire: {
              ...currentQuestionnaire,
              theme: currentTheme,
            },
            referral,
          },
        },

        onCompleted(data) {
          if (!data.saveQuestionnaire) return
          if (!isDraft && !referral && campaign.referralCampaign) {
            navigate(`/campaigns/referral-questionnaire/${id}`)
          } else {
            !isDraft && navigate(`/campaigns/incentives/${id}`)
          }
        },
      })
  }

  const onModalSubmit: SubmitHandler<{ question: string }> = (data, e) => {
    if (!currentQuestionnaire) return
    const buttonName = (
      (e?.nativeEvent as SubmitEvent) || undefined
    )?.submitter?.getAttribute('name')

    buttonName === 'add-question' &&
      setQuestionnaire(
        withButton({
          ...currentQuestionnaire,
          form: [
            ...currentQuestionnaire.form,
            {
              className: `${
                currentQuestionnaire.name ===
                'questionnaire.questionnaire_1.name'
                  ? 'productServiceWrapper'
                  : 'questionnaireWrapper'
              } my-2`,
              children: [
                {
                  name: `optionalQuestion-${data.question.replace('.', '')}`,
                  type: 'input',
                  uiOptions: {
                    label: `${data.question}`,
                    labelClassName: `${
                      currentQuestionnaire.name ===
                      'questionnaire.questionnaire_1.name'
                        ? 'pl-4'
                        : ''
                    } text-md text-theme-primary`,
                    inputType: 'text',
                  },
                },
              ],
            },
          ],
        }),
      )
    setShowModal(false)
  }

  const Portal = () => {
    return (
      <>
        <Button
          onClick={() => setShowModal(true)}
          label={t('optional_question.title')}
          size="small-icon"
          className="disabled:opacity-40 disabled:cursor-auto"
          testId="add-optional-question"
        />
        {showModal &&
          createPortal(
            <OpenQuestion
              onModalSubmit={onModalSubmit}
              onClose={() => setShowModal(false)}
            />,
            document.body,
          )}
      </>
    )
  }

  return (
    <>
      {currentQuestionnaire && (
        <div className="relative overflow-hidden w-full">
          {referral && (
            <>
              <div className="flex felx-row items-center pb-4 gap-2">
                <p className="text-2xl font-bold ">
                  {t('questionnaire.questionnaire')}
                </p>
                <p className="text-black-4 bg-violet-800 rounded px-1">
                  {t('dashboard_labels.referral_campaign')}
                </p>
              </div>
              <p className="text-gray-2 font-light pb-4 max-w-lg">
                {t('questionnaire.referral_campaign_description')}
              </p>
            </>
          )}
          <h1 className="text-2xl font-bold">{t('questionnaire.theme')}</h1>
          <p className="text-gray-2 font-light">
            {t('questionnaire.select_theme')}
          </p>

          <div className="flex flex-row gap-6 py-4">
            {themes.map((theme) => (
              <button
                className={`theme-${theme.key} rounded-xl ${
                  currentTheme.key === theme.key
                    ? 'border-2 border-secondary'
                    : 'border-2 border-transparent'
                }`}
                key={theme.name}
                disabled={referral}
                onClick={() => setCurrentTheme(theme)}
              >
                <img
                  src={`${theme.key === 'theme1' ? theme1Image : theme2Image}`}
                  className="w-28"
                />
              </button>
            ))}
          </div>

          <h1 className="text-2xl font-bold">
            {t('questionnaire.questionnaire')}
          </h1>
          <p className="text-gray-2 font-light">
            {t('questionnaire.select_questionnaire')}
          </p>
          <div className="flex flex-row gap-6 py-4">
            {questionnaires.map((questionnaire) => {
              return (
                <button
                  key={questionnaire.name}
                  className={`flex flex-col w-[175px] ${
                    currentQuestionnaire &&
                    currentQuestionnaire.name === questionnaire.name
                      ? 'border-2 border-secondary'
                      : 'border-2 border-transparent'
                  } rounded-lg shadow-sm`}
                  onClick={() => {
                    const selectedQuestionnaire =
                      campaign?.questionnaire &&
                      questionnaire?.name === campaign?.questionnaire?.name
                        ? campaign.questionnaire
                        : questionnaire
                    selectedQuestionnaire.form.map(
                      (field: AlphaTypes, index: number) => {
                        if (
                          isLayout(field) &&
                          !isLayout(field.children?.[0]) &&
                          field.children?.[0].validations?.includes('required')
                        ) {
                          dispatch({
                            type: 'add',
                            questionIndex: index,
                          })
                        } else {
                          dispatch({
                            type: 'remove',
                            questionIndex: index,
                          })
                        }
                      },
                    )

                    setQuestionnaire(withButton(selectedQuestionnaire))
                  }}
                >
                  <img
                    src={questionnaire.image}
                    width={175}
                    className="rounded-lg"
                  />
                  <span className="py-2 px-4 text-sm text-left">
                    {t(questionnaire.name)}
                  </span>
                </button>
              )
            })}
          </div>
          <h1>{t('questionnaire.review_selected_questionnaire')}</h1>
          {currentQuestionnaire && currentTheme && (
            <>
              <div className="flex justify-between">
                <Portal />
                <Button
                  onClick={() => {
                    if (
                      currentQuestionnaire.name ===
                      'questionnaire.questionnaire_1.name'
                    ) {
                      setQuestionnaire(withButton(questionnaires[0]))
                    } else {
                      setQuestionnaire(withButton(questionnaires[1]))
                    }
                    dispatch({
                      type: 'reset',
                      questionIndex: 0,
                    })
                    setIsEdited(false)
                  }}
                  label={t('optional_question.reverse_changes')}
                  size="small-icon"
                  className="border-none text-secondary disabled:opacity-40 disabled:cursor-auto"
                  disabled={!isEdited}
                />
              </div>
              <div className={`theme-${currentTheme.key}`}>
                <AlphaForm
                  fields={currentQuestionnaire && currentQuestionnaire.form}
                />
              </div>
            </>
          )}

          <CampaignButtons
            onSaveDraft={() => onSubmit(true)}
            onSubmit={() => onSubmit(false)}
          />
        </div>
      )}
    </>
  )
}
