import { MouseEvent } from 'react'
import cx from 'classnames'
import { IconWithShadow } from '../iconWithShadow'
import { Icon, IconWeight } from '@phosphor-icons/react'

type Props = {
  text?: string
  className?: string
  Icon: Icon
  iconWeight?: IconWeight
  isRounded?: boolean
  groupSize: 'big' | 'small'
  onClick?: (evt: MouseEvent<HTMLDivElement>) => void
}
export const DashboardTile = ({ ...props }: Props) => {
  const { Icon, groupSize, iconWeight, isRounded, text, className, onClick } =
    props
  return (
    <div
      onClick={onClick}
      className={cx(
        'flex flex-col items-center px-9 py-8 mx-2.5 gap-4 isolate font-serif',
        'bg-white rounded-lg border border-1 border-gray-3 hover:border hover:border-gray-3 hover:bg-ui1 hover:rounded-lg cursor-pointer',
        className,
      )}
    >
      <div>
        <IconWithShadow
          Icon={Icon}
          groupSize={groupSize}
          iconWeight={iconWeight}
          isRounded={isRounded}
        />
      </div>
      <span className="text-lg text-black-1 text-center font-bold">{text}</span>
    </div>
  )
}
