import React from 'react'
import cx from 'classnames'
import {
  Calendar,
  HardDrive,
  ListChecks,
  Gift,
  Icon,
  EnvelopeOpen,
  Smiley,
} from '@phosphor-icons/react'
import { useRecoilValue } from 'recoil'
import { activeStepState } from '../../store/atoms/active-step'
import colors from '../../../tailwind.config'
import { useTranslation } from 'react-i18next'
import { NavLink, useParams } from 'react-router-dom'

type ProgressBarStep = {
  name: string
  url: string
  icon: Icon
  step: number
  hide?: boolean
  onClick?: () => void
}

export const stepRoutes = (step: number, id: string, referral?: boolean) => {
  const routes = [
    id ? `/campaigns/details/${id}` : '#',
    id ? `/campaigns/end-users/${id}` : '#',
    id ? `/campaigns/questionnaire/${id}` : '#',
    id ? `/campaigns/referral-questionnaire/${id}` : '#',
    id ? `/campaigns/incentives/${id}` : '#',
    id ? `/campaigns/referral-incentives/${id}` : '#',
    id ? `/campaigns/compose-creative/${id}` : '#',
    id ? `/campaigns/happiness-check/${id}` : '#',
  ]

  if (referral && step > 4) return routes[step + 1]
  return routes[step] ?? routes[6]
}

export const ProgressBar: React.FC<{
  completedStep: number
  showReferralQuestionnaire: boolean
  hasReferralIncentives: boolean
}> = ({ completedStep, showReferralQuestionnaire, hasReferralIncentives }) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const activeStep = useRecoilValue(activeStepState)
  const steps: ProgressBarStep[] = [
    {
      name: t('progress_bar.campaign_details'),
      url: id && 1 <= completedStep + 1 ? `/campaigns/details/${id}` : '#',
      icon: Calendar,
      step: 1,
    },
    {
      name: t('progress_bar.data_upload'),
      url: id && 2 <= completedStep + 1 ? `/campaigns/end-users/${id}` : '#',
      icon: HardDrive,
      step: 2,
    },
    {
      name: t('progress_bar.questionnaire'),
      url:
        id && 3 <= completedStep + 1 ? `/campaigns/questionnaire/${id}` : '#',
      icon: ListChecks,
      step: 3,
    },
    {
      name: t('progress_bar.referral_questionnaire'),
      url:
        id && 4 <= completedStep + 1
          ? `/campaigns/referral-questionnaire/${id}`
          : '#',
      icon: ListChecks,
      step: 4,
      hide: showReferralQuestionnaire === false,
    },
    {
      name: t('progress_bar.incentive'),
      url: id && 5 <= completedStep + 1 ? `/campaigns/incentives/${id}` : '#',
      icon: Gift,
      step: 5,
    },
    {
      name: t('progress_bar.referral_incentive'),
      url:
        id && 6 <= completedStep + 1
          ? `/campaigns/referral-incentives/${id}`
          : '#',
      icon: Gift,
      step: 6,
      hide: hasReferralIncentives === false,
    },
    {
      name: t('progress_bar.compose_creative'),
      url:
        id && 7 <= completedStep + 1
          ? `/campaigns/compose-creative/${id}`
          : '#',
      icon: EnvelopeOpen,
      step: 7,
    },
    {
      name: t('progress_bar.happiness_check'),
      url:
        id && 8 <= completedStep + 1 ? `/campaigns/happiness-check/${id}` : '#',
      icon: Smiley,
      step: 8,
    },
  ].filter((step) => !step.hide)
  const isCompleted = (step: ProgressBarStep) =>
    step.step <= activeStep || step.step <= completedStep
  const isCurrent = (step: ProgressBarStep) => step.step === activeStep
  const isUpcomming = (step: ProgressBarStep) =>
    step.step > activeStep && step.step > completedStep

  return (
    <nav aria-label="Progress" className="min-w-fit">
      <ol role="list" className="overflow-hidden">
        {steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className={cx({ 'pb-5': stepIdx !== steps.length - 1 }, 'relative')}
          >
            <NavLink to={step.url}>
              {stepIdx !== steps.length - 1 && (
                <div
                  className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                  aria-hidden="true"
                />
              )}
              <div
                className={'group relative flex items-center cursor-pointer'}
              >
                <span className="flex h-9 items-center">
                  <span
                    className={cx(
                      'relative z-10 flex h-8 w-8 items-center justify-center rounded-full',
                      {
                        'border-2': isCurrent(step) || isCompleted(step),
                      },
                      { 'bg-accent': isCompleted(step) },
                      { 'border-gray-3': isUpcomming(step) },
                      {
                        'border-accent': isCompleted(step) || isCurrent(step),
                      },
                      { border: isUpcomming(step) },
                      { 'border-accent': isCurrent(step) },
                      {
                        'bg-white': isCurrent(step) || isUpcomming(step),
                      },
                    )}
                  >
                    <step.icon
                      size={32}
                      weight="fill"
                      color={
                        isUpcomming(step)
                          ? colors.theme.extend.colors.gray[3]
                          : colors.theme.extend.colors.black[1]
                      }
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </span>
                </span>
                <span className="ml-4 flex min-w-0 flex-col">
                  <span className="text-sm font-medium">{step.name}</span>
                </span>
              </div>
            </NavLink>
          </li>
        ))}
      </ol>
    </nav>
  )
}
