import { FormProps } from '@sourcelabbg/form/lib'
import { RadioGroupSurveyInput } from './custom-types'
import style from './form.module.css'
import cx from 'classnames'
import { useState } from 'react'

export default function RadioGroupSurvey({
  field,
  formProps,
}: {
  field: RadioGroupSurveyInput
  formProps: FormProps
}) {
  const [isSelected, setIsSelected] = useState<string>()
  return (
    <>
      <div className={style.rangeOptions}>
        {field.options && field.options[0].description && (
          <span className={style.radioInputSurvey}>
            {field.options[0].description}
          </span>
        )}
        {field.options &&
          field.options.map((option) => (
            <label
              onClick={() => setIsSelected(option.name)}
              htmlFor={`${field.name}-${option.name}`}
              className={cx(
                style.radioGender,
                isSelected === option.name && 'border-black-1',
              )}
              key={`${field.name}-${option.name}`}
            >
              <span className="p-2">{option.name}</span>
              <input
                {...formProps.register(field.name, {
                  required: field.validations?.includes('required'),
                })}
                id={`${field.name}-${option.name}`}
                type="radio"
                value={option.value}
              />
            </label>
          ))}
        {field.options &&
          field.options[field.options?.length - 1].description && (
            <span className={style.radioInputSurvey}>
              {field.options[field.options?.length - 1].description}
            </span>
          )}
      </div>
    </>
  )
}
