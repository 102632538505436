import { useTranslation } from 'react-i18next'
import { WarningDiamond } from '@phosphor-icons/react'
import { Button } from '@/components/button'

type ErrorModalProps = {
  title: string
  error: string
  onClick: () => void
  hasOnSubmit?: boolean
  onSubmit?: () => void
  submitButtonText: string
}

export default function ErrorModal({
  title,
  error,
  onClick,
  hasOnSubmit = false,
  onSubmit,
  submitButtonText,
}: ErrorModalProps) {
  const { t } = useTranslation()

  return (
    <div className="absolute h-screen">
      <div className="fixed top-0 left-0 z-30 w-full h-screen bg-white opacity-75"></div>
      <div className="flex justify-center fixed top-20 left-2/4 z-40 bg-blue-300">
        <div className="flex flex-col w-[500px] absolute p-6 z-50 bg-white rounded-md shadow-md overflow-y-scroll">
          <div className="flex flex-row justify-center align-middle items-center gap-x-4">
            <WarningDiamond size={42} color="#490091" weight="duotone" />
            <p className="text-lg font-bold text-gray-9">{title}</p>
          </div>
          <div className="py-8 px-6">{error}</div>
          <div className="flex bg-white justify-end space-x-4">
            {hasOnSubmit && (
              <Button
                onClick={onSubmit}
                type="submit"
                name="submit"
                label={submitButtonText}
                data-testid="submit"
              />
            )}
            <Button
              primary={hasOnSubmit ? true : false}
              label={hasOnSubmit ? t('cancel') : t('end_users.ok')}
              onClick={onClick}
              className="px-6"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
