import { FormProps } from '@sourcelabbg/form/lib'
import cx from 'classnames'
import style from './form.module.css'
import { useState } from 'react'
import { RangeCustomInput } from './custom-types'
export default function RangeInput({
  field,
  formProps,
}: {
  field: RangeCustomInput
  formProps: FormProps
}) {
  const [range, setRange] = useState<string>()
  const seCurrentValue = (value: string) => {
    setRange(value)
    formProps.setValue(field.name, value, { shouldValidate: true })
  }
  const options = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

  return (
    <div className="range">
      <input
        {...formProps?.register(field.name, {
          disabled: field.uiOptions?.disabled,
          value: field.value,
        })}
        data-testid={field.uiOptions?.testId}
        type="range"
        id="range"
        name={field.name}
        list="markers"
        min="0"
        max="10"
        step={1}
        onChange={(e) => {
          seCurrentValue(e.target.value)
        }}
        className={style.range}
      />
      <div className={style.rangeOptions}>
        {options.map((option) => (
          <button
            key={option}
            onClick={() => seCurrentValue(option.toString())}
            type="button"
            className={cx(
              option <= Number(range) ? style.active : style.inactive,
            )}
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  )
}
