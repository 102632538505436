import { FormProps } from '@sourcelabbg/form/lib'
import { YesNoRadioInput } from '../custom-types'
import { useTranslation } from 'react-i18next'

export default function YesNoRadio({
  field,
  formProps,
}: {
  field: YesNoRadioInput
  formProps: FormProps
}) {
  const { t } = useTranslation()
  return (
    <div className="flex flex-row gap-2">
      <label
        htmlFor={`${field.name}-yes`}
        className="flex flex-row-reverse gap-1 items-center"
      >
        <span>{field?.options?.[0].name ?? t('yes')}</span>
        <input
          {...formProps.register(field.name, {
            setValueAs: (value) => {
              if (value !== null) return value.toString()
              else return null
            },
          })}
          id={`${field.name}-yes`}
          type="radio"
          value={field?.options?.[0].value}
          className="text-primary focus:ring-primary"
        />
      </label>
      <label
        htmlFor={`${field.name}-no`}
        className="flex flex-row-reverse gap-1 items-center"
      >
        <span>{field?.options?.[1].name ?? t('no')}</span>
        <input
          {...formProps.register(field.name, {
            setValueAs: (value) => {
              if (value !== null) return value.toString()
              else return null
            },
          })}
          id={`${field.name}-no`}
          type="radio"
          value={field?.options?.[1].value}
          className="ml-1 text-primary focus:ring-primary"
        />
      </label>
    </div>
  )
}
