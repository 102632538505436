import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels'

export type ChartData = {
  happinessLevel: string
  count: number
}

type Props = {
  // labels?: string[]
  data: ChartData[]
}

export default function StackedBarChart({ data }: Props) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ChartDataLabels,
    // DataLabels,
    Title,
    Tooltip,
    Legend,
  )

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    barThickness: 66,
    plugins: {
      // plugins,
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        labels: {
          value: {
            color: 'black',
          },
          title: {
            align: 'top' as const,
            offset: 30,
            font: {
              weight: 'normal' as const,
              style: 'normal' as const,
              size: 25,
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            formatter: function (value, ctx: Context) {
              return ctx.dataset.label
            },
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        display: false,
      },
      y: {
        display: false,
        stacked: true,
      },
    },
  }

  const labels = ['']

  const datasets = data
    .filter((item) => item.happinessLevel !== null)
    .map((item) => {
      if (item.happinessLevel === '1') {
        return {
          label: '🙁',
          data: labels.map(() => item.count),
          backgroundColor: 'rgba(251, 227, 142, 0.7)',
          barThickness: 32,
        }
      }
      if (item.happinessLevel === '2') {
        return {
          label: '😶',
          data: labels.map(() => item.count),
          backgroundColor: 'rgba(254, 222, 243, 0.7)',
          barThickness: 32,
        }
      }
      if (item.happinessLevel === '3') {
        return {
          label: '😐',
          data: labels.map(() => item.count),
          backgroundColor: 'rgba(73, 0, 145, 0.4)',
          barThickness: 32,
        }
      }
      if (item.happinessLevel === '4') {
        return {
          label: '🙂',
          data: labels.map(() => item.count),
          backgroundColor: 'rgba(208, 232, 255, 0.7)',
          barThickness: 32,
        }
      }
      if (item.happinessLevel === '5') {
        return {
          label: '😉',
          data: labels.map(() => item.count),
          backgroundColor: 'rgba(60, 220, 75, 0.6)',
          barThickness: 32,
        }
      }
    })

  const parsedData = {
    labels,
    datasets: datasets,
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Bar options={options} data={parsedData} />
}
