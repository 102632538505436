import { gql } from '@apollo/client'

export default gql`
  query EndUsersHappinessCheck($campaignId: ID!, $referral: Boolean) {
    endUsersHappinessCheck(campaignId: $campaignId, referral: $referral) {
      happinessLevel
      count
    }
  }
`
