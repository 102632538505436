import { gql } from '@apollo/client'

export default gql`
  query IncentivesByCategory($incentiveCategoryId: ID, $customerId: ID) {
    incentivesByCategory(
      incentiveCategoryId: $incentiveCategoryId
      customerId: $customerId
    ) {
      id
      campaignId
      customerId
      name
      price
      description
      isAvailable
      type
      picture
      skuCode
      categoryId
      isActive
      categories {
        id
        incentiveCategory {
          id
          name
        }
      }
      isAttachedToLotteryCampaign
      incentiveAttachedToCampaign
      files {
        id
        s3Key
        url
        aggregateId
        fileType
        fileVariant
      }
    }
  }
`
