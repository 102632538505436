import { useMemo, useState } from 'react'
import { previousFriday, previousThursday, startOfDay } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { ApolloError, useLazyQuery, useQuery } from '@apollo/client'

import { GetCampaignFragment } from '@/graphql/campaign/fragments/get-campaign'
import { Button } from '../../../../components/ui/button'
import { DateRangePicker } from '../../../../components/ui/date-range-picker'
import ONE_COMMUNITY_PURCHASES from '../../../../graphql/campaign/queries/integrations/one-community-purchases'
import { EndUsersUploadedError } from '../../../../../../server/src/graphql'
import { toUtcStartDay, toUtcEndDay } from '../../../../lib/utils'
import PURCHASES_QUERY from '../../../../graphql/campaign/queries/latest-purchase-date-range'

export default function OneCommunityPurchases({
  campaign,
  onComplete,
  setErrors,
  setShowModal,
}: {
  campaign: GetCampaignFragment
  onComplete: () => void
  setErrors: (errors?: EndUsersUploadedError[]) => void
  setShowModal: (show: boolean) => void
}) {
  const { data: purchaseDateRange } = useQuery(PURCHASES_QUERY, {
    variables: { id: campaign.id },
  })

  const hasOneCommunityPurchasesIntegration = useMemo(
    () =>
      campaign?.campaignCategory === 'generic' &&
      campaign?.customer?.integrations?.some(
        (i) => i?.type === 'ONE_COMMUNITY' && i.features?.purchases?.enabled,
      ),
    [campaign?.campaignCategory, campaign?.customer?.integrations],
  )

  const { t } = useTranslation()

  const [dateRange, setDateRange] = useState<
    { from: Date; to: Date } | undefined
  >(
    campaign.optionalSettings?.oneCommunity?.dateRange
      ? {
          from: new Date(
            campaign.optionalSettings.oneCommunity.dateRange.from.substring(
              0,
              10,
            ),
          ),
          to: new Date(
            campaign.optionalSettings.oneCommunity.dateRange.to.substring(
              0,
              10,
            ),
          ),
        }
      : {
          from: startOfDay(previousFriday(previousThursday(new Date()))),
          to: startOfDay(previousThursday(new Date())),
        },
  )

  const [fetchOneCommunityPurchases, { loading }] = useLazyQuery(
    ONE_COMMUNITY_PURCHASES,
    {
      variables: {},
      onCompleted: () => onComplete(),
      onError: (error: ApolloError) => {
        const originalError = error.graphQLErrors?.at(0)?.extensions as {
          originalError: { message: EndUsersUploadedError[] }
        }
        setErrors(originalError?.['originalError']?.['message'])
        setShowModal(true)
      },
    },
  )

  if (!hasOneCommunityPurchasesIntegration) return <></>

  return (
    <div className="flex flex-row justify-between bg-ui1 rounded-lg py-4 px-2 items-center">
      <div className="text-gray-2 text-sm">
        <>
          <h1 className="px-1 text-base font-bold">
            {t('api_integration.generic_header')}
          </h1>
          <p className="p-1 text-sm font-normal">
            {t('api_integration.generic_text')}
          </p>
        </>

        <div className="w-1/5 grid">
          <DateRangePicker
            value={dateRange}
            setValue={(d) => {
              if (d?.from && d?.to) setDateRange({ from: d.from, to: d.to })
            }}
            latestPurchaseDateRange={purchaseDateRange?.latestPurchaseDateRange}
          />
        </div>
      </div>
      <Button
        onClick={() => {
          fetchOneCommunityPurchases({
            variables: {
              dateRange: {
                from: dateRange?.from && toUtcStartDay(dateRange?.from),
                to: dateRange?.to && toUtcEndDay(dateRange?.to),
              },
              campaignId: campaign.id,
            },
          })
        }}
        className="flex flex-row cursor-pointer text-white"
        disabled={loading || !dateRange}
      >
        {loading ? 'Loading...' : t('api_integration.sync')}
      </Button>
    </div>
  )
}
