import { textWrapper } from '@/utils/graph-text-wrapper'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

export type ChartData = {
  label: string
  data: string
}
type Props = {
  // options: string
  data: ChartData[]
  type: 'campaign' | 'referral' | 'incentive'
}
export default function BarChart({ data, type }: Props) {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend)
  const optionsBarChart = {
    plugins: {
      legend: {
        display: false,
        labels: {
          font: {
            size: 12,
          },
        },
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        border: { display: false },
        grid: {
          lineWidth: 0,
        },
        ticks: {
          display: true,
        },
      },
      y: {
        border: { display: false },
        ticks: { count: 3 },
      },
    },
  }

  const campaignBgColors = [
    'rgba(73, 0, 145, 0.4)',
    'rgba(139, 7, 250, 0.4)',
    'rgba(96, 6, 172, 0.4)',
    'rgba(201, 167, 255, 0.4)',
    'rgba(223, 206, 255, 0.4)',
  ]

  const campaignBorderColors = [
    'rgba(73, 0, 145, 1)',
    'rgba(139, 7, 250, 1)',
    'rgba(96, 6, 172, 1)',
    'rgba(201, 167, 255, 1)',
    'rgba(223, 206, 255, 1)',
  ]

  const incentiveBgColors = [
    'rgba(208, 232, 255, 0.4)',
    'rgba(0, 111, 253, 0.4)',
    'rgba(24, 18, 255, 0.4)',
    'rgba(9, 17, 133, 0.4)',
  ]

  const incentiveBorderColors = [
    'rgba(208, 232, 255, 1)',
    'rgba(0, 111, 253, 1)',
    'rgba(24, 18, 255, 1)',
    'rgba(9, 17, 133, 1)',
  ]

  const limit = 18
  const dataWithModifiedLabels = textWrapper(data, limit)

  const dataCampaignOverview = {
    labels: dataWithModifiedLabels.map((d) => d.label),
    datasets: [
      {
        label: '',
        data: dataWithModifiedLabels.map((d) => d.data),
        backgroundColor:
          type === 'campaign' ? campaignBgColors : incentiveBgColors,
        borderColor:
          type === 'campaign' ? campaignBorderColors : incentiveBorderColors,
        borderWidth: 2,
        borderRadius: 8,
        borderSkipped: false,
      },
    ],
  }
  return <Bar options={optionsBarChart} data={dataCampaignOverview} />
}
