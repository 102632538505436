import { gql } from '@apollo/client'

export default gql`
  mutation printFilesDownload($campaignId: ID!) {
    generatePrint(campaignId: $campaignId) {
      id
      printFilesLink
    }
  }
`
