'use client'

import { CalendarIcon } from '@radix-ui/react-icons'
import { addMinutes, format, parseISO, subMinutes } from 'date-fns'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import React from 'react'

export function DatePicker({
  value,
  setValue,
}: {
  setValue: (value: string) => void
  value?: string
}) {
  const [open, setOpen] = React.useState(false)
  const currentDate = value ? parseISO(value) : undefined
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant={'outline'}
          className={cn(
            'datePickerClient bg-white',
            'w-full pl-3 text-left font-normal',
            !value && 'text-muted-foreground',
          )}
        >
          {currentDate ? (
            format(currentDate, 'dd MMMM yyyy')
          ) : (
            <span>Pick a date</span>
          )}
          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          mode="single"
          weekStartsOn={1}
          selected={currentDate ?? new Date()}
          defaultMonth={currentDate || new Date()}
          onSelect={(newDate) => {
            if (!newDate) return

            const minutesOffsetToUtc = newDate.getTimezoneOffset()
            const newDateStr =
              minutesOffsetToUtc > 0
                ? addMinutes(newDate, minutesOffsetToUtc)
                : subMinutes(newDate, minutesOffsetToUtc)

            setValue(newDateStr.toISOString())
            setOpen(false)
          }}
          fromYear={1900}
          toYear={new Date().getFullYear() + 1}
          captionLayout="dropdown-buttons"
        />
      </PopoverContent>
    </Popover>
  )
}
