import { gql } from '@apollo/client'

export default gql`
  query Customers {
    customers {
      id
      name
    }
  }
`
