import { FormProps, Input } from '@sourcelabbg/form/lib'
import { Controller } from 'react-hook-form'
import { DatePicker } from '@/components/ui/date-picker'

export default function CustomDate({
  field,
  formProps,
}: {
  field: Input
  formProps: FormProps
}) {
  return (
    <Controller
      control={formProps.control}
      name={field.name}
      render={({ field: { onChange } }) => {
        return (
          <div data-testid={field.name}>
            <DatePicker
              value={formProps.formValues[field.name]}
              setValue={(d) => {
                onChange(d.substring(0, 10))
              }}
            />
          </div>
        )
      }}
    />
  )
}
