import { Icon, IconWeight, X } from '@phosphor-icons/react'
import cx from 'classnames'

type ChipProps = React.ComponentProps<'button'> & {
  title: string
  iconWeight?: IconWeight
  IconLeft?: Icon
  id: string
  onClick?: () => void
  selectedCategory?: string
}

export const Chip = ({
  title,
  IconLeft,
  id,
  onClick,
  selectedCategory,
}: ChipProps) => {
  return (
    <div
      key={id}
      className={cx(
        'flex flex-row items-center justify-center text-secondary px-4 py-2 rounded-full shadow-md cursor-pointer',
        `${selectedCategory === id ? 'bg-violet-30' : 'bg-white'}`,
      )}
      onClick={onClick}
    >
      <div className="flex flex-row items-center gap-2">
        {IconLeft && <IconLeft {...{ weight: 'light', size: '30px' }} />}
        <span className="whitespace-nowrap">{title}</span>
        {selectedCategory === id && <X {...{ weight: 'bold', size: '16px' }} />}
      </div>
    </div>
  )
}
