import { FormProps } from '@sourcelabbg/form/lib'
import { RadioGroupSt } from './custom-types'
import style from './form.module.css'

export default function RadioGroupStandard({
  field,
  formProps,
}: {
  field: RadioGroupSt
  formProps: FormProps
}) {
  return (
    <div className={style.customRadioInputWrapper}>
      {field.options &&
        field.options.map((option) => (
          <label
            htmlFor={`${field.name}-${option.name}`}
            className={style.customRadioLabel}
            key={`${field.name}-${option.name}`}
          >
            <span className={style.radioInputName}>{option.name}</span>
            <input
              {...formProps.register(field.name)}
              id={`${field.name}-${option.name}`}
              type="radio"
              value={option.value}
              className={style.customRadioInput}
            />
          </label>
        ))}
    </div>
  )
}
