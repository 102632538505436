import { gql } from '@apollo/client'

export default gql`
  query category {
    incentiveCategories {
      id
      parent_id
      name
    }
  }
`
