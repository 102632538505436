// Dutch translation file
import { Translations } from '../i18n/en'

const progress_bar = {
  campaign_details: 'Campagnedetails',
  data_upload: 'Gegevens uploaden',
  questionnaire: 'Vragen',
  referral_questionnaire: 'Vragen voor vrienden',
  incentive: 'Cadeau',
  referral_incentive: 'Cadeau voor een vriend',
  compose_creative: 'Creatie toevoegen',
  happiness_check: 'Happiness check',
}

const campaign_details = {
  details: 'Details',
  campaign_name: 'Campagnenaam',
  company_name: 'Bedrijfsnaam',
  start_date: 'Begindatum',
  end_date: 'Einddatum',
  postage_preferences: 'Post voorkeuren',
  sender_address: 'Afzenderadres',
  referral_campaign: 'Cadeau voor vrienden campagne',
  include_referral:
    'Wilt u de mogelijkheid bieden cadeaus aan vrienden te geven?',
  birthday_campaign: 'Verjaardagcampagne',
  include_birthday:
    'Met een verjaardagcampagne kunt u cadeaus versturen voor de verjaardag van uw klant',
  type_of_campaign: 'Campagnetype',
  offline: 'Offline',
  offline_description: 'Ansichtkaart of Flyer campagne',
  online: 'Online',
  online_description: 'E-mail campagne',
  email_address: 'Email Address',
  email_address_label:
    'Het adres zal verschijnen op de ansichtkaart in geval van een offline campagne',
  privacy_link: 'Privacyverklaring Link',
  privacy_link_description:
    'The link to your privacy statement that will be included in our terms and conditions nl',
}

const languages = {
  en: 'Switch to English',
  nl: 'Switch to Dutch',
}

const navigation = {
  create_campaign: 'Maak campagne',
  dashboard: 'Dashboard',
  campaign_list: 'Campagne overzicht',
  agenda: 'Agenda',
  log_out: 'Uitloggen',
  hi: 'Hoi',
  action_panel: 'Acties',
  get_started: 'Om aan de slag te gaan, selecteert u eenvoudig',
  get_started_2: 'een van deze acties',
  incentives: 'Cadeaus',
}

const campaign_list = {
  done: 'klaar',
  in_progress: 'In uitvoering',
  upcoming: 'Komende',
  draft: 'Concept',
  campaign_list: 'Campagne overzicht',
  campaign_name: 'CAMPAGNENAAM',
  start_date: 'begindatum',
  end_date: 'EINDDATUM',
  status: 'STATUS',
  claimed_gifts: 'GECLAIMDE cadeaus',
  details: 'Details',
  edit: 'Bewerk',
}

const questionnaire = {
  theme: 'Thema',
  select_theme: 'Door een thema te kiezen bepaal je de stijl van je campagne',
  referral_theme_preselected:
    'The theme of the campaign is set in the original questionnaire nl',
  questionnaire: 'Vragenlijst',
  select_questionnaire: 'Hieronder kunt u een set vragen kiezen en aanpassen',
  questionnaire_1: {
    name: 'Persoonlijk',
  },
  questionnaire_2: { name: 'Tevredenheid / interesse' },
  review_selected_questionnaire: 'Pas geselecteerde vragenlijst aan',
  referral_campaign: 'Send a friend campagne',
  referral_campaign_description:
    'Klanten kunnen de campagne eenvoudig delen met hun vrienden en contacten via onze send a friend functionaliteit.',
}

const validation_messages = {
  required: 'Dit veld is verplicht',
  Required: 'Dit veld is verplicht',
  invalid_date: 'Ongeldige datum',
  start_date_too_soon_offline: 'Startdatum moet minimaal over 21 dagen zijn',
  start_date_too_soon_online: 'Startdatum moet minimaal over 1 dag zijn',
  campaign_length_too_short: 'De campagne moet minimaal 1 dag lang zijn',
  empty_incentive: 'Selecteer ten minste één item',
  invalid_number: 'Voer geldig nummer in',
  invalid_postcode: 'Ongeldige postcode',
  invalid_email: 'Ongeldige e-mailindeling',
  invalid_house_number: 'Ongeldig huisnummer',
  invalid_phone_number: 'Ongeldig telefoonnummer',
  invalid_url: 'Ongeldige URL',
  missing_picture: 'Please upload an image to add new incentive. nl',
}

const end_users = {
  end_users: 'Eindgebruiker',
  preview: 'Voorbeeld',
  data_check: 'Gegevens controleren',
  data_upload: 'Gegevens uploaden',
  download_example: 'voorbeeldbestand',
  table_header: {
    first_name: 'Voornaam',
    middle: 'Tussenvoegsel',
    last_name: 'Achternaam',
    email: 'E-mail',
    phone_number: 'Telefoonnummer',
    postal_code: 'Postcode',
    city: 'Plaats',
    street_name: 'Straatnaam',
    house_number: 'Huisnummer',
    country: 'Land',
    birthday: 'Geboortedag',
    sku: 'SKU',
    digital_code: 'Digital Code',
    postnl_code: 'Dutch post Code nl',
    reference_code: 'Reference Code nl',
  },
  map_field_placeholder: '[Kolomnaam]',
  missing_data: 'Het lijkt erop dat er gegevens ontbreken',
  missing_data_text:
    'De volgende gegevens ontbreken in de upload. Upload een nieuw bestand met de ontbrekende gegevens.',
  upload: 'UPLOADEN',
  missing: 'MISSEND',
  errors: {
    firstName: 'Voornaam',
    middle: 'Tussenvoegsel',
    lastName: 'Achternaam',
    email: 'Email',
    sku: 'SKU',
    postnlCode: 'Dutch post code nl',
    referenceCode: 'Reference Code nl',
    postcode: 'Postcode',
    postalCode: 'Postcode',
  },
  ok: 'OK ik snap het',
}

const file_upload = {
  a: 'een',
  or: 'of',
  upload_file: 'Upload',
  drag_and_drop: 'of sleep',
  file: 'bestand',
}

const optional_question = {
  title: 'Nieuwe vraag toevoegen',
  description: 'U kunt hier uw eigen vraag toevoegen',
  question: 'Vraag',
  type: 'Type',
  cancel: 'Annuleren',
  add_question: 'Vraag toevoegen',
  reverse_changes: 'Wijzigingen ongedaan maken',
  question_placeholder:
    'Hoe waarschijnlijk is het dat je ons zou aanbevelen aan een vriend of collega?',
  type_placeholder: 'Open Vraag',
}
const compose_creative = {
  title: 'Creatie toevoegen',
  sub_title: 'Campagnetype',
  offline: 'Offline',
  offline_post_card: 'Ansichtkaart campagne',
  online: 'Online',
  online_email: 'Email campagne',
  postcard: 'Ansichtkaart',
  postcard_description: 'Ansichtkaart campagne',
  flyer: 'Flyer',
  flyer_description: 'Flyer campagne',
}

const incentives = {
  title: 'Cadeaus',
  referral_incentives_title: 'Vrienden',
  select: 'Selecteer',
  selected: 'Geselecteerd',
  types: {
    unique_digital: 'Unieke digitale cadeaucode',
    universal_digital: 'Universele digitale cadeaucode',
    physical: 'Fysieke cadeaus',
  },
  sku_code: 'SKU Code',
  digital_code: 'Universele digitale cadeaucode',
}

const add_incentive = {
  add_incentive: 'Cadeau toevoegen',
  sub_title: 'Choose your own idea for an incentive nl',
  upload_here: 'U kunt uw eigen cadeau hier uploaden',
  name: 'Naam van het cadeau',
  price: 'Prijs',
  description: 'Omschrijving',
  category: 'Categorie',
  type: 'Type',
  customer: 'Klant',
  download_example: 'Download voorbeeldbestand',
  upload_voucher_code_file: 'Upload waardeboncodes',
}

const offline = {
  title_design_front: 'Ansichtkaart ontwerp voorkant',
  content_design_front:
    'Hier kunt u uw eigen ontwerp van de ansichtkaart uploaden.',
  post_card_design: 'Ansichtkaart ontwerp',
  title_design_back: 'Ansichtkaart ontwerp achterkant',
  content_design_back:
    'Hier kun je je eigen logo uploaden en tekst aan de kaart toevoegen',
  logo: 'Logo',
  text_on_the_back: 'Tekst op de achterkant van de kaart',
  landing_text_back: 'Tekst op de landingspagina',
  heading: 'Titel',
  add_text: 'Tekst toevoegen',
  preview: 'Voorbeeld',
  special_gift_awaited: 'Speciaal een cadeau voor u!',
  add_text_placeholder: 'Claim uw cadeau!',
  form_of_address: 'Aanspreekvorm',
  card_design: 'Kaart ontwerp',
  info_card: {
    card_requirements: 'Kaartvereisten',
    post_card_design: 'Ansichtkaart ontwerp',
    safety_margin: 'Veiligheidsmarge',
    cutting_margin: 'Snijmarge',
    yellow: 'Geel',
    red: 'Rood',
    safety_size: ': 148 x 210mm',
    cutting_size: ': 154 x 216mm',
    safety_margin_description:
      "De veiligheidsmarge (gele rand) geeft aan hoe ver belangrijke elementen, zoals teksten en logo's, van de rand van uw ontwerp moeten blijven. Hiermee voorkomt u dat de tekst gedeeltelijk wordt afgesneden of op de rand van uw drukwerk komt te staan.",
    cutting_margin_description:
      'Snijmarge (bleed) is de marge die je in je ontwerp aanhoudt om (witte) snijlijnen aan de rand van je drukwerk te voorkomen. Wij vragen een snijmarge van 3 mm per zijde.',
    tip: 'Tip',
    tip_description:
      'Laat je achtergrond doorlopen tot en met de rode rand (de afloop), hier zit namelijk minimale speling tijdens het knippen. Dit voorkomt witte randen na het uitsnijden en zorgt ervoor dat de achtergrond aan alle kanten mooi doorloopt.',
  },
}

const confirmation = {
  title: 'Uw Campagne is helemaal klaar!',
  description: 'U vindt en beheert al uw campagnes in het campagne overzicht',
  go_to_campaign_list: 'Ga naar campagne overzicht​',
}

const happiness_check = {
  module: 'Hapiness check module',
  include: 'Neem de hapiness check module op',
  really_negative: 'Echt negatief',
  negative: 'Negatief',
  alright: 'goed',
  positive: 'Positief',
  really_positive: 'Echt positief',
}
const online = {
  email_title: 'E-mail ontwerp',
  redeem_gift: 'Cadeau inwisselen',
  email_text: 'E-mail Tekst',
}

const print_files_download = {
  modal_title: 'beveiligde bestandsdownload',
  modal_description: `Dit bestand is beveiligd met een wachtwoord om de grootst mogelijke veiligheid te garanderen. Voer hieronder het juiste wachtwoord in om toegang te krijgen. Ga er zorgvuldig mee om en deel het alleen met geautoriseerde ontvangers.`,
  download: 'Download bestand',
  copied: 'Gekopieerd naar het klembord',
}

const incentive_categories = {
  personal_care: 'Persoonlijke verzorging',
  home: 'Huis',
  other: 'Overige',
  foods_and_drinks: 'Eten & Drinken',
}

const dashboard_labels = {
  dashboard: 'Dashboard',
  general_campaign: 'Algemeen',
  referral_campaign: 'Vrienden',
  campaign_overview: 'Campagne overzicht',
  campaign_performance: 'Campagneprestaties',
  requested_incentives: 'Aangevraagde cadeaus',
  by_share: 'аandeel',
  by_type: 'per type',
  over_time: 'in de loop van de tijd',
  since_the_beginning_of_the_campaign: 'sinds het begin van de campagne',
  happiness_check: 'Happiness Check',
  overview: {
    campaign_reached: 'Campagne bereikt',
    scanned_qr_codes: 'Gescande QR-codes',
    referral_link_opens: 'Aantal links gestuurd naar vrienden geopend',
    questionnaire_started: 'Vragenlijst gestart',
    questionnaire_completed: 'Vragenlijst voltooid',
    incentives_requested: 'Cadeaus aangevraagd',
  },
  performance_by_time: {
    general: {
      reached: 'Nr. QR-codes gescand / website bezoeken',
      incentiveRequested: 'Cadeaus aangevraagd',
      questionnaireStarted: 'Vragen gestart',
      questionnaireCompleted: 'Ingevulde vragen',
    },
    referral: {
      reached: 'Aantal links gestuurd naar vrienden geopend',
      incentiveRequested: 'Cadeaus aangevraagd',
      questionnaireStarted: 'Vragen gestart',
      questionnaireCompleted: 'Ingevulde vragen',
    },
  },
}

const tooltips = {
  generate_files: 'Genereer Bestanden',
  send_email: 'Stuur E-mail',
  download_user_data: 'Gebruikersgegevens Downloaden',
  download_creative: 'Creatieve Downloaden',
}

const flyer = {
  title: 'Flyerontwerp',
  front: 'Voorkant',
  landing_page: 'Klantlandingspagina',
  landing_page_description:
    'Deze tekst verschijnt nadat je klant de QR-code heeft gescand.',
  main_campaign: 'Hoofdcampagne',
  heading: 'Titel',
  add_text: 'Voeg tekst toe',
  referral_campaign: 'Verwijzingscampagne',
  info_card: {
    flyer_requirements: 'Vereisten voor flyers',
    safety_margin: 'Veiligheidsmarge',
    cutting_margin: 'Snijmarge',
    yellow: 'Geel',
    red: 'Rood',
    safety_size: ': 210 x 148mm',
    cutting_size: ': 216 x 154mm',
  },
}

const campaign_categories = {
  title: 'Campagne Categorie',
  generic: 'Algemeen campagne',
  birthday: 'Verjaardag campagne',
  lottery: 'Loterij campagne',
  generic_description:
    'Upload gewoon je gegevenslijst en stuur creatives en incentives op maat naar je publiek',
  birthday_description:
    'Met een verjaardagscampagne kun je incentives versturen voor de verjaardag van je klant',
  lottery_description:
    'Haal de resultaten van je loterijtrekking op en deel ze met je klanten',
}
const api_integration = {
  generic_header: 'Algemene Campagne',
  generic_text:
    'Selecteer alstublieft de datumreeks voor de klantgegevens die u wilt downloaden van de API.',
  lottery_text:
    'Selecteer alstublieft één of meerdere loterijinschrijvingsformulieren voor de klantgegevens die u wilt downloaden van de API.',
  lottery_header: 'Loterij Campagne',
  sync: 'Synchroniseer API-verbinding',
}

const incentive_errors_modal = {
  delete_error_modal_title: 'De stimulans kan niet worden verwijderd',
  delete_incentive_item_is_used:
    'Cadeau item is in {{numberOfCampaigns}} gebruik',
  delete_incentive_with_used_voucher_codes:
    'Kan cadeau item niet verwijderen met gebruikte voucher codes',
  update_error_modal_title:
    'Dit geschenk is te zien in {{numberOfCampaigns}} campagnes',
  update_incentive_item_is_used:
    'De geselecteerde incentive is momenteel opgenomen in {{numberOfCampaigns}} campagnes. Als u deze wilt deactiveren, moet u de incentive uit de campagnes deselecteren',
}

const incentives_store = {
  title: 'Stimulansen',
  new_incentive: 'Nieuw cadeau',
  featured: 'Uitgelicht',
  product_info: 'Product informatie',
  price: 'Prijs',
  category: 'Categorie',
  description: 'Omschrij',
  claimed: 'Geclaimd',
  disabled_active: 'Uitgeschakeld/Aktief',
  pcs: 'stuks',
  add_incentive: 'Nieuw cadeau toevoegen',
  active: 'Actief',
  status: 'Stimuleringsstatus',
  uploaded_files: 'Geüploade bestanden',
  add: 'Cadeau toevoegen',
  no_incentives: 'No incentives added for this customer yet.',
  please_add: 'Please add a new incentive',
  featured_tooltip: 'Uitgelicht in {{numberOfCampaigns}} campagnes',
  previous: 'Vorige',
  next: 'Volgende',
}

const translation: Translations = {
  locale: 'nl-NL',
  yes: 'Ja',
  no: 'Nee’',
  save_draft: 'Concept opslaan',
  next_step: 'Volgende stap',
  save_campaign: 'Campagne opslaan',
  language: 'Taal',
  calendar: 'Kalender',
  loading: 'Laden',
  create: 'Creëren',
  close: 'Sluit',
  details: 'Details',
  cancel: 'Annuleren',
  formal: 'Formeel',
  informal: 'Informeel',
  save_to_preview: 'Concept opslaan om de preview te zien',
  download_front_pdf: 'Download voorzijde als pdf',
  download_back_pdf: 'Download achterzijde als pdf',
  upload_csv_error:
    'Het bestand heeft niet de juiste indeling. Gebruik alstublieft dezelfde indeling als in het voorbeeldbestand!',
  repeat_incentive: 'Voeg dezelfde cadeaus toe voor vrienden',
  dear: 'Beste',
  hello: 'Beste',
  current_month: 'Huidige maand',
  required: 'Verplicht',
  sku_not_exist:
    'Deze SKU-code "{{sku}}" bestaat niet in de geüploade gegevens',
  sku_exist: 'Deze SKU-code "{{sku}}" bestaat al',
  skuCodes_not_match_data_upload:
    'SKU-codes "{{sku}}" bestaan in de geüploade gegevens, maar niet in de geselecteerde incentive-items',
  campaigns_on: 'campagnes op',
  delete: 'Verwijderen',
  delete_campaign: 'Campagne verwijderen',
  are_you_sure: 'Weet u zeker dat u deze campagne wilt verwijderen?',
  deleting_campaign:
    'Als u de campagne verwijdert, wordt deze uit de lijst en het dashboard verwijderd.',
  multiselect_placeholder: 'Selecteer een optie',
  previous_campaign: 'Vorige campagne',
  day: 'Dag',
  edit: 'Bewerk',
  disable: 'Uitschakelen',
  save: 'Opslaan',
  api_integration,
  languages,
  progress_bar,
  campaign_details,
  navigation,
  campaign_list,
  questionnaire,
  validation_messages,
  end_users,
  file_upload,
  optional_question,
  incentives,
  add_incentive,
  compose_creative,
  offline,
  confirmation,
  happiness_check,
  online,
  print_files_download,
  incentive_categories,
  dashboard_labels,
  tooltips,
  flyer,
  campaign_categories,
  incentive_errors_modal,
  incentives_store,
}

export default translation
