import { ChartData } from '@/components/charts/bar-chart'

export const textWrapper = (data: ChartData[], limit: number) => {
  const res = data.map((d) => {
    const text = d.label
    const words = text?.split(' ')
    const lines = []
    let currentLine = ''
    words?.forEach((word) => {
      const width = currentLine.length
      if (width + word.length >= limit) {
        lines.push(currentLine)
        currentLine = ''
      }
      currentLine += word + ' '
    })
    lines.push(currentLine)
    return {
      label: lines,
      data: d.data,
    }
  })
  return res
}
