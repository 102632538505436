import { Button } from './button'
import AlphaForm from './form'
import { AlphaTypes } from './form/custom-types'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'react-i18next'
import { SubmitHandler } from 'react-hook-form'

const AddQuestionSchema = z
  .object({
    question: z.string({ required_error: 'required' }).min(1).max(140),
  })
  .required()

export default function OpenQuestion({
  onClose,
  onModalSubmit,
}: {
  onClose: () => void
  onModalSubmit: SubmitHandler<{ question: string }>
}) {
  const { t } = useTranslation()
  const openQuestionFields: AlphaTypes[] = [
    {
      render: () => (
        <>
          <h1>{t('optional_question.title')}</h1>
          <p className="text-gray-3">{t('optional_question.description')}</p>
        </>
      ),
    },
    {
      name: 'question',
      type: 'custom',
      customControlType: 'textarea',
      uiOptions: {
        label: t('optional_question.question'),
        labelClassName: 'text-md text-grey-1',
        testId: 'question-textarea',
      },
    },
    {
      name: 'typeQuestion',
      type: 'input',
      uiOptions: {
        label: t('optional_question.type'),
        inputType: 'text',
        disabled: true,
        placeholder: t('optional_question.type_placeholder'),
      },
    },
    {
      render: () => (
        <div className="flex justify-between my-10 w-full">
          <div className="grid grid-cols-2 gap-4 justify-end items-end py-8">
            <Button
              type="button"
              name="cancel"
              label={t('optional_question.cancel')}
              onClick={onClose}
            />
            <Button
              primary={true}
              type="submit"
              name="add-question"
              label={t('optional_question.add_question')}
            />
          </div>
        </div>
      ),
    },
  ]
  return (
    <div>
      <div className="fixed top-0 left-0 z-30 w-full h-screen bg-ui1 opacity-75"></div>
      <div className="flex justify-center fixed top-0 left-0 z-40 w-full h-screen align-center items-center overflow-scroll">
        <div className="flex flex-col absolute items-start p-8 z-50 bg-white rounded-md shadow-md w-[500px] h-fit">
          <div className="w-full">
            <AlphaForm
              fields={openQuestionFields}
              onSubmit={onModalSubmit}
              resolver={zodResolver(AddQuestionSchema)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
