import { gql } from '@apollo/client'

export default gql`
  query agenda {
    holidays {
      id
      name
      startDate
      endDate
    }

    campaigns {
      id
      name
      startDate
      endDate
      details
    }
  }
`
