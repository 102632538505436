import cx from 'classnames'

type Props = {
  title: string
  description?: string
  children?: React.ReactNode
  className?: string
}
export default function DashboardCard({
  title,
  description,
  children,
  className,
}: Props) {
  return (
    <div
      className={cx(
        'flex  flex-col  border border-ui1 rounded-lg bg-white',
        className,
      )}
    >
      <div className="flex flex-col justify-start bg-[#FAFAFA] p-4 border-b border-ui1">
        <div className="">
          <h2 className="text-base font-bold">{title}</h2>
          <p className="text-xs italic text-black-3">{description}</p>
        </div>
      </div>
      <div className="p-4 justify-center items-center">{children}</div>
    </div>
  )
}
