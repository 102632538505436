import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { SubmitHandler } from 'react-hook-form'
import CREATE_CAMPAIGN from '../../../graphql/campaign/mutations/create'
import { useSetRecoilState } from 'recoil'
import { activeStepState } from '../../../store/atoms/active-step'
import { useEffect } from 'react'
import {
  CreateCampaignType,
  CreateCampaignSchema,
} from '@/schema/campaign-details.schema'
import CampaignDetailsForm from './form'

export default function CreateCampaign() {
  const [createCampaign] = useMutation(CREATE_CAMPAIGN, {})
  const setActiveStep = useSetRecoilState(activeStepState)
  const navigate = useNavigate()
  useEffect(() => setActiveStep(1), [setActiveStep])

  const onSubmit: SubmitHandler<CreateCampaignType> = (data, e) => {
    const buttonName = (
      (e?.nativeEvent as SubmitEvent) || undefined
    )?.submitter?.getAttribute('name')
    createCampaign({
      variables: {
        campaign: {
          ...data,
        },
      },
      onCompleted(data) {
        if (buttonName === 'next')
          navigate(`/campaigns/end-users/${data.createCampaign.id}`)
        else navigate(`/campaigns/details/${data.createCampaign.id}`)
      },
    })
  }

  return (
    <CampaignDetailsForm onSubmit={onSubmit} schema={CreateCampaignSchema} />
  )
}
