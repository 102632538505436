import { z } from 'zod'

export const CampaignIncentivesSchema = z.object({
  campaignId: z.string(),
  incentiveItems: z
    .array(z.object({ id: z.string().min(1), skuCode: z.string().optional() }))
    .nonempty(),
  referral: z.boolean(),
  reuseSameIncentives: z.boolean().optional(),
})

export type CampaignIncentivesType = z.infer<typeof CampaignIncentivesSchema>
