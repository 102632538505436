import { gql } from '@apollo/client'

export default gql`
  query OneCommunityForms($campaignId: String!) {
    oneCommunityForms(campaignId: $campaignId) {
      id
      date
      label
    }
  }
`
