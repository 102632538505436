import { gql } from '@apollo/client'

export default gql`
  query GetCampaignLatestPurchaseDateRange($id: ID!) {
    latestPurchaseDateRange(id: $id) {
      startDate
      endDate
    }
  }
`
