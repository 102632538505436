import { gql } from '@apollo/client'

export default gql`
  query OneCommunityPurchases($dateRange: DateRange!, $campaignId: String!) {
    oneCommunityPurchases(dateRange: $dateRange, campaignId: $campaignId) {
      id
      firstName
      middle
      lastName
      email
      address
      birthday
      campaignId
      campaignIncentiveId
      phoneNumber
      sku
      digitalCode
      uniqueCode
    }
  }
`
