import { FormProps } from '@sourcelabbg/form/lib'
import { TextareaInput } from './custom-types'

export default function CustomTextarea({
  field,
  formProps,
}: {
  field: TextareaInput
  formProps: FormProps
}) {
  return (
    <textarea
      rows={4}
      className="block w-full rounded-md border-0 p-2 text-gray-2 shadow-sm ring-1 ring-inset ring-ui1 placeholder:text-gray-2 focus-visible:outline-black-1 sm:text-sm sm:leading-6 placeholder:opacity-50"
      defaultValue={''}
      placeholder={field.uiOptions?.placeholder}
      {...formProps?.register(field.name)}
      data-testid={field.uiOptions?.testId}
      disabled={field.uiOptions?.disabled}
    />
  )
}
