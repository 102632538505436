import { textWrapper } from '@/utils/graph-text-wrapper'
import {
  Chart as ChartJS,
  CategoryScale,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

export type ChartData = {
  label: string
  data: string
}
type Props = {
  data: ChartData[]
}

export default function DoughnutChart({ data }: Props) {
  ChartJS.register(ArcElement, CategoryScale, Tooltip, Legend)
  // ChartJS.overrides.doughnut.plugins.legend.labels.borderRadius = 4
  // ChartJS.overrides.doughnut.plugins.legend.labels.useBorderRadius = true

  const optionsDoughnutChart = {
    responsive: true,
    aspectRatio: 2,
    plugins: {
      responsive: true,
      legend: {
        display: true,
        position: 'right' as const,
        padding: 20,
        labels: {
          pointStyleWidth: 32,
          usePointStyle: true,
          pointStyle: 'rectRounded',
        },
      },
      radius: 200,
      datalabels: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  }

  // override legend

  const limit = 30
  const dataWithModifiedLabels = textWrapper(data, limit)

  const dataDoughnutChart = {
    labels: dataWithModifiedLabels.map((d) => d.label),
    datasets: [
      {
        label: '',
        data: dataWithModifiedLabels.map((d) => d.data),
        backgroundColor: [
          'rgba(208, 232, 255, 0.4)',
          'rgba(0, 111, 253, 0.4)',
          'rgba(24, 18, 255, 0.4)',
          'rgba(9, 17, 133, 0.4)',
        ],
        borderColor: [
          'rgba(208, 232, 255, 1)',
          'rgba(0, 111, 253, 1)',
          'rgba(24, 18, 255, 1)',
          'rgba(9, 17, 133, 1)',
        ],
        borderWidth: 2,
      },
    ],
  }

  return <Doughnut data={dataDoughnutChart} options={optionsDoughnutChart} />
}
