import { FormProps } from '@sourcelabbg/form/lib'
import { useRef, useState } from 'react'
import { FileUploadInput } from './custom-types'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { tokenState } from '../../store/atoms/token'
import { displayS3File } from '../../utils/s3'
import { CloudArrowUp, MicrosoftExcelLogo } from '@phosphor-icons/react'
import { Controller } from 'react-hook-form'
import cx from 'classnames'

export default function CustomFileUpload({
  field,
  formProps,
}: {
  field: FileUploadInput
  formProps: FormProps
}) {
  const { t } = useTranslation()
  const token = useRecoilValue(tokenState)
  const [_file, setFile] = useState<File>()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const isImgFileType = field.allowedFileTypes?.find(
    (fileType) => fileType === 'jpg' || fileType === 'png',
  )
  const xlsFileTypes = ['csv', 'xls', 'xlsx']
  const isSpreadsheet = field.allowedFileTypes?.find((fileType) =>
    xlsFileTypes.includes(fileType),
  )

  const { ref: _ref, ...rest } = formProps.register(field.name)

  return (
    <Controller
      control={formProps.control}
      name={field.name}
      render={({ field: { onChange, value: v } }) => {
        // @ts-ignore
        const getValue = (obj: Record<string, any>, path: string) => {
          return path.split('.').reduce((o, p) => o[p], obj)
        }
        const value = v ?? getValue(formProps.formValues, field.name)

        const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
          event.preventDefault()
        }

        const handleFileUpload = async (file?: File) => {
          if (!file) {
            return
          }

          const fileReader = new FileReader()

          fileReader.readAsDataURL(file)

          const formData = new FormData()
          formData.append('file', file as Blob)

          field.uploadBody &&
            Object.entries(field.uploadBody).map((f) => {
              const [key, value] = f
              value && formData.append(key, value?.toString())
            })

          try {
            const uploadedFile = await fetch(field.uploadUrl, {
              method: 'POST',
              body: formData,
              headers: {
                Authorization: `Bearer ${token?.__raw}`,
              },
            })

            const fileName = await uploadedFile.text()

            onChange(fileName)
          } catch (error) {
            console.error(error)
          }
        }

        const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
          event.preventDefault()
          const files = event.dataTransfer.files
          if (files.length !== 1) {
            // TODO: Add a visual error message in the area
            console.log('Please drop one file at a time')
            return
          }
          handleFileUpload(files[0])
          setFile(files[0])
        }

        const handleFileSelect = (
          event: React.ChangeEvent<HTMLInputElement>,
        ) => {
          const files = event.target.files
          if (files?.length === 1) {
            handleFileUpload(files[0])
            setFile(files[0])
          }
        }

        const openFilePicker = (event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation()
          fileInputRef.current?.click()
        }

        const allowedFiles =
          field.allowedFileTypes
            ?.map((fileType) => fileType.toUpperCase())
            ?.map((fileType) => (fileType === 'XLSX' ? 'Excel' : fileType)) ||
          []

        return (
          <div
            className={cx(
              field.uiOptions?.disabled
                ? 'pointer-events-none'
                : 'cursor-pointer',
              'flex flex-col items-center space-y-1 border-2 border-dashed border-gray-5 rounded-md pt-6 pb-7',
            )}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={openFilePicker}
          >
            <div className="flex flex-row space-between w-full items-center">
              <div className="flex-1 mx-4">
                {isImgFileType && value && (
                  <img
                    className="rounded-md max-h-40"
                    src={displayS3File(value?.toString())}
                  />
                )}
                {isSpreadsheet && value && (
                  <MicrosoftExcelLogo size={32} className="self-center" />
                )}
              </div>
              <div className="flex-1 flex flex-col items-center">
                <CloudArrowUp size={48} />
                <p className="text-center">
                  <span className="text-secondary">
                    {t('file_upload.upload_file')}
                  </span>
                  <span> {t('file_upload.drag_and_drop')}</span>
                  <span className="block text-gray-400">
                    {t('file_upload.a')}{' '}
                    {allowedFiles.length === 1
                      ? allowedFiles.join(' ')
                      : allowedFiles.slice(0, -1).join(' ') +
                        ' ' +
                        t('file_upload.or') +
                        ' ' +
                        allowedFiles.slice(-1)}{' '}
                    {t('file_upload.file')}
                  </span>
                </p>
                <input
                  disabled={field.uiOptions?.disabled}
                  data-testid={field?.uiOptions?.testId}
                  type="file"
                  className="hidden"
                  {...rest}
                  ref={fileInputRef}
                  onChange={handleFileSelect}
                  accept={field.allowedFileTypes
                    ?.map((fileTypeExt) => `.${fileTypeExt}`)
                    .join(',')}
                />
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}
