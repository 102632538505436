import { FormProps } from '@sourcelabbg/form/lib'
import { RadioGroupGenderInput } from './custom-types'
import { useEffect, useState } from 'react'
import cx from 'classnames'
import style from './form.module.css'

export default function RadioGroupGender({
  field,
  formProps,
}: {
  field: RadioGroupGenderInput
  formProps: FormProps
}) {
  const [isSelected, setIsSelected] = useState<string>()
  useEffect(() => {
    setIsSelected(field.value)
  }, [field.value])

  return (
    <>
      <div className="flex flex-row gap-4 pl-1.5 pt-4">
        <label
          htmlFor={`${field.name}-man`}
          onClick={() => setIsSelected('man')}
          className={cx(
            style.radioGender,
            'radioGenderClient',
            `${isSelected === 'man' && 'border-black-1'}`,
          )}
          data-testid={`${field.uiOptions?.testId ?? field.name}-man`}
        >
          <input
            {...formProps.register(field.name)}
            type="radio"
            value="man"
            checked={field.value === 'man'}
            className="sr-only"
            id={`${field.name}-man`}
            data-testid={`${field.uiOptions?.testId ?? field.name}-man-input`}
          />
          <span className="font-bold text-gray-2">{'Man'}</span>
        </label>
        <label
          htmlFor={`${field.name}-woman`}
          onClick={() => setIsSelected('woman')}
          className={cx(
            style.radioGender,
            'radioGenderClient',
            `${isSelected === 'woman' && 'border-black-1'}`,
          )}
          data-testid={`${field.uiOptions?.testId ?? field.name}-woman`}
        >
          <input
            {...formProps.register(field.name, {
              required: field.validations?.includes('required'),
            })}
            type="radio"
            value="woman"
            checked={field.value === 'woman'}
            className="sr-only"
            id={`${field.name}-woman`}
            data-testid={`${field.uiOptions?.testId ?? field.name}-woman-input`}
          />
          <span className="font-bold text-gray-2">{'Vrouw'}</span>
        </label>
        <label
          htmlFor={`${field.name}-different`}
          onClick={() => setIsSelected('different')}
          className={cx(
            style.radioGender,
            'radioGenderClient',
            `${isSelected === 'different' && 'border-black-1'}`,
          )}
          data-testid={`${field.uiOptions?.testId ?? field.name}-different`}
        >
          <input
            {...formProps.register(field.name, {
              required: field.validations?.includes('required'),
            })}
            type="radio"
            value="different"
            checked={field.value === 'different'}
            className="sr-only"
            id={`${field.name}-different`}
            data-testid={`${
              field.uiOptions?.testId ?? field.name
            }-different-input`}
          />
          <span className="font-bold text-gray-2">{'Anders'}</span>
        </label>
      </div>
    </>
  )
}
