import cx from 'classnames'
import { Icon, IconWeight } from '@phosphor-icons/react'

type IconProps = {
  Icon: Icon
  iconWeight?: IconWeight
  isRounded?: boolean
  groupSize: 'big' | 'small'
}

export const IconWithShadow = ({ ...props }: IconProps) => {
  const {
    Icon,
    groupSize = 'small',
    iconWeight = 'regular',
    isRounded = false,
  } = props

  const overallSize =
    groupSize === 'small'
      ? {
          widthAndHeight: 'w-6 h-6',
          iconSize: 28,
          marginOverlay: 'w-6 h-6 ml-2 mt-2',
        }
      : {
          widthAndHeight: 'w-14 h-14',
          iconSize: 45,
          marginOverlay: 'w-10 h-10 ml-4 mt-4',
        }
  return (
    <div className="block">
      <div className={cx('relative', overallSize.widthAndHeight)}>
        <div className="text-black absolute z-10">
          <Icon
            {...{
              weight: iconWeight,
              size: overallSize.iconSize,
              color: 'black',
            }}
          />
        </div>
        <div
          className={cx(
            'absolute bg-accent',
            overallSize.marginOverlay,
            isRounded ? 'rounded-full' : 'rounded-sm',
          )}
        ></div>
      </div>
    </div>
  )
}
