import React from 'react'
import { ApolloProvider, HttpLink, split } from '@apollo/client'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { useRecoilState } from 'recoil'

import { tokenState } from './store/atoms/token'
import { useCallback } from 'react'
import { getMainDefinition } from '@apollo/client/utilities'

type Props = {
  children?: React.ReactNode
}

export const AlphaApolloClient = ({ children }: Props) => {
  const [idToken, setToken] = useRecoilState(tokenState)

  const client = useCallback(() => {
    const prefix = import.meta.env.DEV ? 'ws' : 'wss'
    const wsLink = new GraphQLWsLink(
      createClient({
        url: `${prefix}://${window.location.host}/graphql`,
        connectionParams: {
          authToken: idToken?.__raw || '',
        },
      }),
    )
    const httpLink = new HttpLink({
      uri: `${window.location.origin}/graphql`,
      headers: {
        authorization: `${idToken ? `Bearer ${idToken.__raw}` : ''}`,
      },
    })

    const errorLink = onError(({ graphQLErrors }) => {
      if (
        graphQLErrors?.find((error) => {
          error.message === 'Unauthorized'
        })
      ) {
        setToken(null)
      }
    })

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      },
      wsLink,
      httpLink,
    )

    return new ApolloClient({
      link: errorLink.concat(splitLink),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-first',
          errorPolicy: 'all',
        },
        query: {
          fetchPolicy: 'cache-first',
          errorPolicy: 'all',
        },
      },
      headers: { Authorization: `${idToken ? `Bearer ${idToken.__raw}` : ''}` },
      connectToDevTools: import.meta.env.DEV,
    })
  }, [idToken])

  return <ApolloProvider client={client()}>{children}</ApolloProvider>
}
