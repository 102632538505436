import { gql } from '@apollo/client'

export default gql`
  query incentivesPerCampaign($campaignId: ID!) {
    incentivesPerCampaign(campaignId: $campaignId) {
      general {
        label
        data
      }
      referral {
        label
        data
      }
    }
  }
`
