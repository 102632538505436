import { gql } from '@apollo/client'
import GetCampaignFragment from '../fragments/get-campaign'

export default gql`
  ${GetCampaignFragment}
  mutation AttachCampaignIncentives($incentives: AttachCampaignIncentiveInput) {
    attachCampaignIncentives(incentives: $incentives) {
      ...GetCampaignFragment
    }
  }
`
