import { useMutation } from '@apollo/client'
import { Trash } from '@phosphor-icons/react'

import DELETE_CAMPAIGN_MUTATION from '@/graphql/campaign/mutations/delete-campaign'
import CAMPAIGNS_QUERY from '@/graphql/campaign/queries/list'
import { Button } from '@/components/button'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CampaignTableItem } from '../pages/campaign/list'
import { useTranslation } from 'react-i18next'

const DeleteCampaignModal = ({
  campaignToDelete,
}: {
  campaignToDelete: CampaignTableItem
}) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [deleteCampaign] = useMutation(DELETE_CAMPAIGN_MUTATION, {
    refetchQueries: () => [{ query: CAMPAIGNS_QUERY, variables: {} }],
  })
  const ref = useRef<HTMLDivElement>(null)
  const onClickDelete = () => {
    deleteCampaign({
      variables: { campaignId: campaignToDelete.id },
    })
    setShowModal(false)
  }
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowModal(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  const portal = useMemo(() => {
    if (!showModal) return <></>
    return (
      <div
        className="absolute h-screen"
        data-testid="create-incentive-item-form"
      >
        <div className="fixed top-0 left-0 z-30 w-full h-screen bg-ui1 opacity-10"></div>
        <div className="flex justify-center fixed top-60 left-0 z-40 w-full h-full">
          <div
            ref={ref}
            className="flex flex-col absolute gap-6 p-6 z-50 bg-white rounded-md shadow-md w-[500px] h-fit overflow-y-scroll"
          >
            <div>
              <div className="flex flex-row items-center">
                <Trash size={38} fill="#1812FF" className="mr-4" />
                <div className="gap-1">
                  <p className="text-lg text-gray-9 font-bold pb-1">
                    {t('delete_campaign')} {campaignToDelete.name}
                  </p>
                  <p className="text-md text-gray-10 font-medium py-2">
                    {t('are_you_sure')}
                  </p>
                  <p className="text-sm text-gray-10">
                    {t('deleting_campaign')}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-end gap-2">
              <Button
                label={t('cancel')}
                onClick={() => setShowModal(false)}
                className="text-primary border border-primary hover:opacity-50"
              />
              <Button
                label={t('delete')}
                onClick={onClickDelete}
                primary={true}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }, [campaignToDelete, showModal])

  return (
    <>
      {portal}
      <button
        onClick={() => setShowModal(true)}
        className="text-sm flex flex-row gap-3 items-center border rounded-md px-1"
      >
        <Trash size={16} fill="#490091" />
        {t('delete')}
      </button>
    </>
  )
}

export default DeleteCampaignModal
