import {
  CloudArrowUp,
  ImageSquare,
  MicrosoftExcelLogo,
  X,
} from '@phosphor-icons/react'
import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import formatFileSize from '../util/format-file-size'

type FileUploadProps = {
  allowedFileTypes?: string[]
  onFileUpload: (file: File | undefined) => void
  testId?: string
}

const FileUpload: React.FC<FileUploadProps> = ({
  onFileUpload,
  allowedFileTypes,
  testId,
}) => {
  const { t } = useTranslation()
  const [file, setFile] = useState<File>()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const isImgFileType = allowedFileTypes?.find(
    (fileType) => fileType === 'jpg' || fileType === 'png',
  )

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
    },
    [],
  )

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      const files = event.dataTransfer.files
      if (files.length !== 1) {
        // TODO: Add a visual error message in the area
        console.log('Please drop one file at a time')
        return
      }
      onFileUpload(files[0])
      setFile(files[0])
    },
    [onFileUpload],
  )

  const handleFileSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files
      if (files?.length === 1) {
        onFileUpload(files[0])
        setFile(files[0])
      }
    },
    [onFileUpload],
  )

  const openFilePicker = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    fileInputRef.current?.click()
  }, [])

  const handleReset = useCallback(() => {
    setFile(undefined)
    onFileUpload(undefined)
  }, [onFileUpload])

  if (file) {
    return (
      <div>
        <div className="flex flex-row gap-2 p-4 border border-gray-5 rounded-md">
          {isImgFileType ? (
            <ImageSquare size={32} />
          ) : (
            <MicrosoftExcelLogo size={32} className="self-center" />
          )}
          <div className="flex flex-col grow justify-between gap-2 break-all">
            <p className="text-gray-900 text-lg leading-6 font-medium">
              {file.name}
            </p>
            <p className="text-gray-500 text-sm leading-5 font-normal">
              {formatFileSize(file.size)}
            </p>
          </div>
          <X
            size={24}
            className="self-center cursor-pointer"
            onClick={handleReset}
          />
        </div>
      </div>
    )
  }
  const allowedFiles =
    allowedFileTypes
      ?.map((fileType) => fileType.toUpperCase())
      ?.map((fileType) => (fileType === 'XLSX' ? 'Excel' : fileType)) || []

  return (
    <div
      className="flex flex-col items-center space-y-1 border-2 border-dashed border-gray-5 rounded-md pt-6 pb-7 cursor-pointer"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={openFilePicker}
    >
      <CloudArrowUp size={48} />
      <p className="text-center">
        <span className="text-secondary">{t('file_upload.upload_file')}</span>
        <span> {t('file_upload.drag_and_drop')}</span>
        <span className="block text-gray-400">
          {t('file_upload.a')}{' '}
          {allowedFiles.length === 1
            ? allowedFiles.join(' ')
            : allowedFiles.slice(0, -1).join(' ') +
              ' ' +
              t('file_upload.or') +
              ' ' +
              allowedFiles.slice(-1)}{' '}
          {t('file_upload.file')}
        </span>
      </p>
      <input
        data-testid={testId}
        ref={fileInputRef}
        type="file"
        className="hidden"
        onChange={handleFileSelect}
        accept={allowedFileTypes
          ?.map((fileTypeExt) => `.${fileTypeExt}`)
          .join(',')}
      />
    </div>
  )
}

export default FileUpload
