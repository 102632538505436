import { gql } from '@apollo/client'

export default gql`
  query GetVouchersInfo($campaignId: ID!) {
    getVouchersInfo(campaignId: $campaignId) {
      incentiveItemId
      incentiveItemName
      voucherCodes {
        id
        isUsed
      }
    }
  }
`
