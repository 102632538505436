import { textWrapper } from '@/utils/graph-text-wrapper'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

export type ChartData = {
  label: string
  data: string
}
type Props = {
  labels: string[]
  data: ChartData[]
}
export default function LineChart({ data, labels }: Props) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
  )

  const optionsLineChart = {
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        display: true,
        position: 'right' as const,
        maxWidth: 150,
        labels: {
          padding: 20,
          pointStyleWidth: 32,
          usePointStyle: true,
          pointStyle: 'rectRounded',
        },
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        border: { display: false },
        grid: {
          lineWidth: 0,
        },
        ticks: {
          display: true,
          font: {
            size: 12,
            weight: 'bold',
          },
        },
      },
      y: {
        beginAtZero: true,
        border: { display: false },
        ticks: { count: 3 },
      },
    },
  }

  const bgColors = [
    'rgba(73, 0, 145, 0.4)',
    'rgba(139, 7, 250, 0.4)',
    'rgba(96, 6, 172, 0.4)',
    'rgba(201, 167, 255, 0.4)',
    'rgba(223, 206, 255, 0.4)',
  ]

  const borderColors = [
    'rgba(73, 0, 145, 1)',
    'rgba(139, 7, 250, 1)',
    'rgba(96, 6, 172, 1)',
    'rgba(201, 167, 255, 1)',
    'rgba(223, 206, 255, 1)',
  ]

  const limit = 15
  const dataWithModifiedLabels = textWrapper(data, limit)

  const dataLineChart = {
    labels: labels,
    datasets: [
      ...dataWithModifiedLabels.map((d, index) => {
        return {
          label: d.label,
          data: d.data,
          borderColor: borderColors[index],
          backgroundColor: bgColors[index],
          pointRadius: 6,
          borderWidth: 2,
        }
      }),
    ],
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Line options={optionsLineChart} data={dataLineChart} />
}
