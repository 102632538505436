import { FormProps } from '@sourcelabbg/form/lib'
import { HiddenInput } from './custom-types'

export default function CustomHiddenInput({
  field,
  formProps,
}: {
  field: HiddenInput
  formProps: FormProps
}) {
  return (
    <input
      type="hidden"
      {...formProps?.register(field.name, {
        value: field.value,
      })}
    />
  )
}
