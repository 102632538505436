import { gql } from '@apollo/client'
// import GetCampaignFragment from '../fragments/get-campaign'

export default gql`
  mutation saveQuestionnaire($questionnaire: SaveQuestionnaireInput) {
    saveQuestionnaire(questionnaire: $questionnaire) {
      id
      referralCampaign
      questionnaire
      referralQuestionnaire
    }
  }
`
