import { gql } from '@apollo/client'

export default gql`
  query c {
    campaigns {
      id
      name
      typeOfCampaign
      companyName
      startDate
      endDate
      details
      referralCampaign
      isDraft
      postcardTemplate
      updatedAt
      completedStep
      printFilesLink
      printPassword
      campaignCategory
      customer {
        id
        name
      }
    }
  }
`
