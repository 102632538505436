const formatFileSize = (size: number): string => {
  if (size < 1024) {
    // Size is in bytes
    return size + ' B'
  } else if (size < 1024 * 1024) {
    // Size is in kilobytes
    return (size / 1024).toFixed(2) + ' KB'
  } else if (size < 1024 * 1024 * 1024) {
    // Size is in megabytes
    return (size / 1024 / 1024).toFixed(2) + ' MB'
  } else {
    // Size is in gigabytes
    return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
  }
}
export default formatFileSize
