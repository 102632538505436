import { gql } from '@apollo/client'
import GetCampaignFragment from '../fragments/get-campaign'

export default gql`
  ${GetCampaignFragment}
  mutation ExecuteCampaign($campaignId: ID!) {
    executeCampaign(campaignId: $campaignId) {
      ...GetCampaignFragment
    }
  }
`
