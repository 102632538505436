import { FormProps } from '@sourcelabbg/form/lib'
import { Controller } from 'react-hook-form'
import { DatePicker } from '@/components/ui/date-picker'
import { Combobox } from '../ui/combobox'
import { useEffect, useState } from 'react'
import { DateTimeInput } from './custom-types'
import { format } from 'date-fns'

export default function CustomDateTime({
  field,
  formProps,
}: {
  field: DateTimeInput
  formProps: FormProps
}) {
  const [date, setDate] = useState<string>('')
  const [hour, setHour] = useState<string>('00')
  const [minute, setMinute] = useState<string>('00')
  const hours = Array.from({ length: 24 }, (_, i) =>
    i < 10 ? `0${i}` : i.toString(),
  )
  const minutes = Array.from({ length: 60 }, (_, i) =>
    i < 10 ? `0${i}` : i.toString(),
  )

  return (
    <Controller
      control={formProps.control}
      name={field.name}
      render={({ field: { onChange, value } }) => {
        const localDate = format(
          new Date(value ?? formProps.formValues[field.name]),
          'yyyy-MM-dd HH:mm',
        )
        const [d, t] = localDate.split(' ')
        const [h, m] = t.split(':')
        useEffect(() => {
          if (value) {
            if (d !== date) setDate(d)
            if (h !== hour) setHour(h)
            if (m !== minute) setMinute(m)
          }
        }, [value, d, h, m])

        useEffect(() => {
          if (!date || !hour || !minute) return
          const newDate = new Date(date + ` ${hour}:${minute}:00`).toISOString()

          if (newDate !== value) {
            onChange(new Date(date + ` ${hour}:${minute}:00`).toISOString())
          }
        }, [onChange, value, date, hour, minute])

        return (
          <div>
            <div data-testid={field.name} className="flex gap-2">
              <div className={field.uiOptions?.className}>
                <DatePicker
                  value={d}
                  setValue={(d) => setDate(d.substring(0, 10))}
                />
              </div>
              <div className="flex-col">
                <div className="flex gap-2">
                  <Combobox
                    type="single"
                    value={hour.toString()}
                    placeholder="Hour"
                    className="w-2 h-10"
                    setValue={(v) => setHour(v || '00')}
                    options={hours.map((h) => ({ value: h, label: h }))}
                  />

                  <Combobox
                    type="single"
                    value={minute.toString()}
                    placeholder="Minute"
                    className="w-2 h-10"
                    setValue={(v) => setMinute(v || '00')}
                    options={minutes.map((m) => ({ value: m, label: m }))}
                  />
                </div>
              </div>
            </div>
            <div className="my-auto py-1 text-sm text-gray-3">
              {format(
                new Date(value ?? formProps.formValues[field.name]),
                'yyyy-MM-dd HH:mm',
              )}{' '}
              ({Intl.DateTimeFormat().resolvedOptions().timeZone})
            </div>
          </div>
        )
      }}
    />
  )
}
