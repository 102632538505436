import { useState } from 'react'
import { Controller } from 'react-hook-form'
import ReactSelect from 'react-select'
import { FormProps, Select as SelectProps } from '@sourcelabbg/form/lib'

export type SelectOption = {
  label: string
  value: string
  data: unknown
  disabled?: boolean
}

export type SelectSetter = (selected: SelectOption[]) => void

export type CustomSelectProps = {
  field: SelectProps
  formProps: FormProps
}

export function CustomSelect({ field, formProps }: CustomSelectProps) {
  const [values, setValues] = useState<SelectOption[]>()
  if (field.listen?.on) {
    formProps.listeners.addListener(
      field.listen.on,
      field.name,
      field.listen.execute(setValues),
    )
  }

  const options = values || field.options
  return (
    <Controller
      control={formProps.control}
      name={field.name}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        const selected = field.options.find((option) => option.value === value)
        return (
          <ReactSelect
            id={field.name}
            options={options}
            placeholder={field.uiOptions?.placeholder}
            value={selected}
            onChange={(v) => onChange(v?.value)}
            onBlur={onBlur}
            ref={ref}
            className={`${field?.uiOptions?.className} ${
              field.hideSeparator ? 'reactSelect' : ''
            }`}
            isSearchable={field.uiOptions?.isSearchable}
            isDisabled={field.uiOptions?.disabled}
            isOptionDisabled={(option: SelectOption) =>
              option.disabled ? option.disabled : false
            }
            menuPlacement={field.menuPlacement}
            data-testid={field.uiOptions?.testId}
          />
        )
      }}
    />
  )
}
