import { gql } from '@apollo/client'
import GetIncentiveItemFragment from '../fragments/get-incentive-item'

export default gql`
  ${GetIncentiveItemFragment}
  mutation createIncentiveItem(
    $id: ID
    $incentiveItem: CreateIncentiveItemInput
  ) {
    createIncentiveItem(id: $id, incentiveItem: $incentiveItem) {
      ...GetIncentiveItemFragment
    }
  }
`
