import { useTranslation } from 'react-i18next'
import confirmation from '../../assets/images/confirmation.png'
import { Button } from '@/components/button'
import { useNavigate } from 'react-router-dom'

export default function Confirmation() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <div className="flex flex-col h-full items-center justify-center gap-y-6 max-w-[500px] m-auto">
      <img src={confirmation} />
      <p className="text-4xl font-bold whitespace-nowrap">
        {t('confirmation.title')}
      </p>
      <p className="text-xl text-center w-[330px]">
        {t('confirmation.description')}
      </p>
      <Button
        onClick={() => navigate('/campaigns/list')}
        name="goToCampaignList"
        label={t('confirmation.go_to_campaign_list')}
        className="border-primary text-primary border-2"
      />
    </div>
  )
}
