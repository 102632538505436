import { FormProps, Input } from '@sourcelabbg/form/lib'
import style from './form.module.css'
export default function CustomInputNumber({
  field,
  formProps,
}: {
  field: Input
  formProps: FormProps
}) {
  return (
    <input
      type="number"
      className={style.customInputNumber}
      {...formProps?.register(field.name)}
      data-testid={field.uiOptions?.testId}
      disabled={field.uiOptions?.disabled}
    />
  )
}
