import { FormProps, Input } from '@sourcelabbg/form/lib'

import style from './form.module.css'
export default function CustomInput({
  field,
  formProps,
}: {
  field: Input
  formProps: FormProps
}) {
  return (
    <input
      type="text"
      placeholder={field.uiOptions?.placeholder}
      className={style.customInput}
      {...formProps?.register(field.name, {
        value: field.value,
      })}
      data-testid={field.uiOptions?.testId}
      disabled={field.uiOptions?.disabled}
    />
  )
}
