import { useState } from 'react'
import { Button } from '../button'
import { useTranslation } from 'react-i18next'
import { createPortal } from 'react-dom'
import IncentiveModal from './incentive-modal'

type CreateIncentiveProps = {
  className?: string
}

export const CreateIncentive = ({ className }: CreateIncentiveProps) => {
  const { t } = useTranslation()

  const [showModal, setShowModal] = useState(false)
  return (
    <div className="relative h-full" data-testid="create-incentive-item">
      <Button
        onClick={() => setShowModal(true)}
        label={t('add_incentive.add_incentive')}
        className={className}
        data-testid="add-incentive-item-button"
        isIncentive={true}
      />
      {showModal &&
        createPortal(
          <IncentiveModal onCloseModal={() => setShowModal(false)} />,
          document.body,
        )}
    </div>
  )
}
