import * as React from 'react'
import { type Ref } from 'react'
import { Check, ChevronsUpDown, X } from 'lucide-react'
import { cn } from '@/lib/utils.ts'
import { Badge } from '@/components/ui/badge'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from './command'
import { Popover, PopoverContent, PopoverTrigger } from './popover'
import useResizeObserver from 'use-resize-observer'

type Option<T extends string = string> = {
  value: T
  label: string
}

type BaseProps<T extends string = string> = {
  name?: string
  options: Option<T>[]
  placeholder?: string
  searchable?: boolean
  className?: string
  type: 'single' | 'multiple'
}

export type SingleSelectProps<T extends string> = BaseProps<T> & {
  value?: T | null
  setValue?: (value: T | null) => void
  type: 'single'
}

export type MultipleSelectProps<T extends string> = BaseProps<T> & {
  value?: T[]
  setValue?: (value: T[]) => void
  type: 'multiple'
}

export type ComboboxProps<T extends string> =
  | SingleSelectProps<T>
  | MultipleSelectProps<T>

const Combobox = <T extends string>(
  props: ComboboxProps<T> & { cRef?: Ref<HTMLButtonElement> },
) => {
  const [open, setOpen] = React.useState(false)
  const { ref: resizeRef, width = 1 } = useResizeObserver<HTMLDivElement>({
    box: 'border-box',
  })

  const optionsMap = React.useMemo(() => {
    return props.options.reduce((acc, item) => {
      acc[item.value] = item
      return acc
    }, {} as Record<string, Option>)
  }, [props.options])
  const handleUnselect = (item: string) => {
    // TS needs a little help here
    props.type === 'multiple'
      ? props.setValue?.(props.value?.filter((i) => i !== item) ?? [])
      : props.setValue?.(null)
  }
  const { options, placeholder } = props
  const handleToggle = (item: T) => {
    const isMultiple = props.type === 'multiple'
    if (isMultiple) {
      if (props.value?.includes(item)) {
        props.setValue?.(props.value?.filter((i) => i !== item) ?? [])
      } else {
        props.setValue?.([...(props.value ?? []), item])
      }
    } else {
      props.setValue?.(props.value === item ? null : item)
    }
  }
  const isSelected = (item: T) =>
    props.type === 'multiple'
      ? props.value?.includes(item)
      : props.value === item

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger ref={props.cRef} asChild className={props.className}>
        <div
          ref={resizeRef}
          tabIndex={0}
          role="combobox"
          aria-expanded={open}
          className="text-sm flex w-full min-w-[var(--radix-select-trigger-width)] flex-row items-center justify-between rounded-md border border-control bg-white px-3 py-2  font-normal text-gray-2"
        >
          {props.type === 'multiple' ? (
            <div className="flex w-full flex-row flex-wrap gap-1 bg-white">
              {props.value &&
              Array.isArray(props.value) &&
              props.value.length > 0 ? (
                props.value.map((item) => (
                  <Badge
                    variant="outline"
                    className="rounded-full border-gray-3 bg-gray-10 py-1 pl-4 text-sm font-medium"
                    key={item}
                  >
                    {optionsMap[item]?.label}
                    <button
                      type="button"
                      className="ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleUnselect(item)
                        }
                      }}
                    >
                      <X
                        onMouseDown={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                        }}
                        onClickCapture={() => handleUnselect(item)}
                        className="h-3 w-3 text-muted-foreground hover:text-foreground"
                      />
                    </button>
                  </Badge>
                ))
              ) : (
                <span className="text-gray-1">
                  {placeholder ?? 'Select value(s)'}
                </span>
              )}
            </div>
          ) : props.value && optionsMap[props.value]?.label ? (
            <span className="cursor-default text-foreground">
              {optionsMap[props.value]?.label}
            </span>
          ) : (
            <span className="cursor-default text-gray-1">
              {placeholder ?? 'Select value'}
            </span>
          )}
          <ChevronsUpDown className="ml-2 h-5 w-5 shrink-0 opacity-90" />
        </div>
      </PopoverTrigger>
      <PopoverContent
        style={{ width: width, minWidth: 'min-content' }}
        className="p-0"
      >
        <Command>
          {props.searchable !== false && (
            <>
              <CommandInput placeholder={placeholder} />
              <CommandEmpty>Nothing found.</CommandEmpty>
            </>
          )}
          <CommandGroup className="max-h-[300px] overflow-y-auto">
            {options.map((option) => (
              <CommandItem
                key={option.value}
                value={option.label}
                onSelect={() => {
                  handleToggle(option.value)
                  setOpen(props.type === 'multiple')
                }}
              >
                <div className="flex mr-3">
                  <Check
                    className={cn(
                      isSelected(option.value) ? 'opacity-100' : 'opacity-0',
                      'mr-2 h-4 w-4',
                    )}
                  />
                  {option.label}
                </div>
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export { Combobox }
