import { gql } from '@apollo/client'

export default gql`
  query campaignPerformanceOverTime($campaignId: ID!) {
    campaignPerformanceOverTime(campaignId: $campaignId) {
      general {
        labels
        data {
          label
          data
        }
      }
      referral {
        labels
        data {
          label
          data
        }
      }
    }
  }
`
